import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {setCurrentStore} from "../../../services/CurrentStoreService"
import getHistory from "../../../store/getHistory"
import {fetchStoreSettingsBySlug} from "../../../services/api/SellerServices"

class ChangeToStore extends Component {
    state = {
        loading: false,
        error: ''
    }

    componentDidMount() {
        this._setUp()
    }

    _setUp = async () => {
        await this._getStoreSettings()

        const history = getHistory()
        history.push('/a')
    }

    _getStoreSettings = async () => {
        const {id} = this.props

        this.setState({
            loading: true
        })

        try {
            const {success, data, message} = await fetchStoreSettingsBySlug(id)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            setCurrentStore(id, data)

            this.setState({
                loading: false,
                error: ''
            })

            return id
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e
            })
        }

        return false
    }

    render() {
        const {id} = this.props

        return (
            <div className="ChangeToStore">
                <div className="ChangeToStoreInner container">
                    <p>You are going to store {id}...</p>
                </div>
            </div>
        )
    }
}

ChangeToStore.propTypes = {
    id: PropTypes.string.isRequired,
}

export default ChangeToStore
