import React, {Component} from 'react'
import {Link} from "react-router-dom"
import {createFulfillment, getOrder} from "../../../services/api/OrdersServices"
import OrderBuyerDetails from '../../order/components/OrderBuyerDetails'
import FulfillQuantity from "./FulfillQuantity"
import TrackingInformation from "./TrackingInformation"
import NotifyCustomer from "./NotifyCustomer"

class OrderFulfillmentContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: props.match.params.id,
            order: {},
            items: [],
            itemsQuantity: [],
            messageItems: ''
        }
        this.fulfillQuantity = React.createRef()
        this.trackingInformation = React.createRef()
        this.notifyCustomer = React.createRef()
    }

    componentDidMount() {
        this._getOrder()
    }

    _getOrder = async () => {
        const {orderId} = this.state
        const {success, message, data} = await getOrder(orderId)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            order: data
        })
    }

    _fulfillment = async () => {
        const {orderId} = this.state
        const {notify_customer} = this.notifyCustomer.current.state
        const {itemsQuantity, items} = this.fulfillQuantity.current.state
        let {tracking_number, tracking_url, tracking_company} = this.trackingInformation.current.state
        if (tracking_company === 'None') {
            tracking_number = ''
            tracking_url = ''
            tracking_company = ''
        }
        let itemsValid = items

        itemsValid.forEach((item, i) => [
            itemsValid[i].quantity = itemsQuantity[i]
        ])
        const args = {
            orderId: orderId,
            tracking_number: tracking_number,
            tracking_url: tracking_url,
            tracking_company: tracking_company,
            items: itemsValid,
            notify_customer: notify_customer
        }

        const {success, message} = await createFulfillment(args)

        if (!success) {
            return alert(message)
        }

        window.location.pathname = `a/orders/${this.state.orderId}`
    }

    render() {
        const {order, orderId} = this.state

        return (
            <div className="OrderFulfillmentContainer container-fluid">
                <Link to={`/a/orders/${order._id}`}>
                    <p className="text-muted"><i className="fas fa-chevron-left"/> Order#{order.code}</p>
                </Link>
                <h4 className="font-weight-bold">Fulfillment</h4>
                <div className="Fulfillment row">
                    <div className="col-8">
                        <div className="MarkAsFulfilled card">
                            <div className="card-header font-weight-bold">
                                Mark as fulfilled
                            </div>
                            <FulfillQuantity orderId={orderId} ref={this.fulfillQuantity}/>
                            <TrackingInformation ref={this.trackingInformation}/>
                            <NotifyCustomer ref={this.notifyCustomer}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <OrderBuyerDetails orderId={orderId}/>
                        <div className="Summary card">
                            <div className="card-header font-weight-bold">
                                Summary
                            </div>
                            <div className="card-body">
                                <div>
                                    Fulfilling from ...
                                </div>
                                <div>
                                    ...
                                </div>
                            </div>
                            <div className="card-body text-center">
                                <button className="btn btn-sm btn-primary btn-block"
                                        onClick={() => this._fulfillment()}>Fulfill items
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderFulfillmentContainer
