import React, {Component} from "react"
import SettingSectionDetails from "../../setting/shared/SettingSectionDetails"
import ProductInfo from "./ProductInfo"
import {getSettingsByCategory, saveSettingsByCategory} from "../../../services/api/SettingServices"
import classNames from "classnames";

class SettingProductContainer extends Component {
    state = {
        loading: {
            fetchSettings: false,
            spin: false,
        },
        form: {
            "shipping_info": "",
            "show_featured_collections": "",
            "is_co_countdown_show": "",
            "is_product_countdown_show": "",
            "happy_customer_uri": "",
            "show_track_order": "",
            "discount_value": ""
        }
    }

    componentDidMount() {
        this._getSettings()
    }

    _getSettings = async () => {
        this.setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                fetchSettings: true,
            }
        }))

        const {success, data} = await getSettingsByCategory('product')
        if (success) {
            const form = data.reduce((accumulator, setting) => ({
                ...accumulator,
                [setting.key]: setting.value,
            }), {})
            this.setState(state => ({
                ...state,
                loading: {
                    ...state.loading,
                    fetchSettings: false,
                },
                form: {
                    ...state.form,
                    ...form,
                }
            }))
        }
    }

    _clickSaveButton = () => {
        this._saveSettings()
    }

    _saveSettings = async () => {
        const {form} = this.state
        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: true,
            }
        }))

        const {success} = await saveSettingsByCategory('product', Object.keys(form).map((key) => ({
            key,
            value: form[key]
        })))

        return this.setState(state => ({
            loading: {
                ...state.loading,
                spin: false,
                saveSuccess: success,
            }
        }))
    }

    _onChangeSetting = (key, value) => {
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [key]: value,
            }
        }))
    }

    render() {
        const {loading, form} = this.state
        const {_onChangeSetting} = this
        const spin = loading.spin

        return <div className="SettingsContainer">
            <div className="Section">
                <SettingSectionDetails
                    title="Preferences"
                />
                <div className="SectionSetting">
                    <div className="card Card">
                        <div className="card-body shadow-sm">
                            <ProductInfo
                                loading={loading}
                                form={form}
                                onChange={_onChangeSetting}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ButtonSave">
                <button
                    className="btn btn-primary"
                    onClick={this._clickSaveButton}
                    disabled={loading.spin}
                >
                    <span className="Label">Save</span>
                    <span className={classNames("Spin", {spin})}/>
                </button>
            </div>
        </div>
    }

}

export default SettingProductContainer
