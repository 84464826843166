import React, {Component} from 'react'
import classNames from 'classnames'
import {createStore} from "../../../services/api/SellerServices"
import PubSub from '../PubSub'

class CreateStoreForm extends Component {
    state = {
        form: {
            name: '',
            domain: '',
        },
        loading: '',
        error: ''
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._submit()
    }

    _submit = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        const {form} = this.state
        try {
            const {success, data, message} = await createStore(form)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            PubSub.publish('STORE_CREATE_NEW_ONE', data)

            this.setState({
                loading: false,
                error: '',
                form: {
                    name: '',
                    domain: ''
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e
            })
        }
    }

    _handleChangeInput = field => e => {
        const {value} = e.target

        this.setState(({form}) => ({
            form: {
                ...form,
                [field]: value
            }
        }))
    }

    render() {
        const {loading, error, form: {name, domain}} = this.state

        return (
            <div className={classNames("CreateStoreForm", {loading})}>
                {
                    !!error &&
                    <div className="Error mb-2">
                        <div className="Text text-danger">{error}</div>
                    </div>
                }

                <form onSubmit={this._handleSubmit} className="Form">
                    <div className="form-group">
                        <input type="text" value={name}
                               onChange={this._handleChangeInput('name')}
                               placeholder="Store name" className="form-control"/>
                    </div>

                    <div className="form-group">
                        <input type="text" value={domain}
                               onChange={this._handleChangeInput('domain')}
                               placeholder="Domain" className="form-control"/>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Create new store</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default CreateStoreForm
