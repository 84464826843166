export const timeZoneList = [
    {
        "title": "(UTC-12:00) International Date Line West",
        "value": "(UTC-12:00) International Date Line West"
    },
    {
        "title": "(UTC-11:00) Coordinated Universal Time-11",
        "value": "(UTC-11:00) Coordinated Universal Time-11"
    },
    {
        "title": "(UTC-10:00) Hawaii",
        "value": "(UTC-10:00) Hawaii"
    },
    {
        "title": "(UTC-09:00) Alaska",
        "value": "(UTC-09:00) Alaska"
    },
    {
        "title": "(UTC-08:00) Baja California",
        "value": "(UTC-08:00) Baja California"
    },
    {
        "title": "(UTC-07:00) Pacific Time (US & Canada)",
        "value": "(UTC-07:00) Pacific Time (US & Canada)"
    },
    {
        "title": "(UTC-08:00) Pacific Time (US & Canada)",
        "value": "(UTC-08:00) Pacific Time (US & Canada)"
    },
    {
        "title": "(UTC-07:00) Arizona",
        "value": "(UTC-07:00) Arizona"
    },
    {
        "title": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        "value": "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        "title": "(UTC-07:00) Mountain Time (US & Canada)",
        "value": "(UTC-07:00) Mountain Time (US & Canada)"
    },
    {
        "title": "(UTC-06:00) Central America",
        "value": "(UTC-06:00) Central America"
    },
    {
        "title": "(UTC-06:00) Central Time (US & Canada)",
        "value": "(UTC-06:00) Central Time (US & Canada)"
    },
    {
        "title": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        "value": "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        "title": "(UTC-06:00) Saskatchewan",
        "value": "(UTC-06:00) Saskatchewan"
    },
    {
        "title": "(UTC-05:00) Bogota, Lima, Quito",
        "value": "(UTC-05:00) Bogota, Lima, Quito"
    },
    {
        "title": "(UTC-05:00) Eastern Time (US & Canada)",
        "value": "(UTC-05:00) Eastern Time (US & Canada)"
    },
    {
        "title": "(UTC-05:00) Indiana (East)",
        "value": "(UTC-05:00) Indiana (East)"
    },
    {
        "title": "(UTC-04:30) Caracas",
        "value": "(UTC-04:30) Caracas"
    },
    {
        "title": "(UTC-04:00) Asuncion",
        "value": "(UTC-04:00) Asuncion"
    },
    {
        "title": "(UTC-04:00) Atlantic Time (Canada)",
        "value": "(UTC-04:00) Atlantic Time (Canada)"
    },
    {
        "title": "(UTC-04:00) Cuiaba",
        "value": "(UTC-04:00) Cuiaba"
    },
    {
        "title": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        "value": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    {
        "title": "(UTC-04:00) Santiago",
        "value": "(UTC-04:00) Santiago"
    },
    {
        "title": "(UTC-03:30) Newfoundland",
        "value": "(UTC-03:30) Newfoundland"
    },
    {
        "title": "(UTC-03:00) Brasilia",
        "value": "(UTC-03:00) Brasilia"
    },
    {
        "title": "(UTC-03:00) Buenos Aires",
        "value": "(UTC-03:00) Buenos Aires"
    },
    {
        "title": "(UTC-03:00) Cayenne, Fortaleza",
        "value": "(UTC-03:00) Cayenne, Fortaleza"
    },
    {
        "title": "(UTC-03:00) Greenland",
        "value": "(UTC-03:00) Greenland"
    },
    {
        "title": "(UTC-03:00) Montevideo",
        "value": "(UTC-03:00) Montevideo"
    },
    {
        "title": "(UTC-03:00) Salvador",
        "value": "(UTC-03:00) Salvador"
    },
    {
        "title": "(UTC-02:00) Coordinated Universal Time-02",
        "value": "(UTC-02:00) Coordinated Universal Time-02"
    },
    {
        "title": "(UTC-02:00) Mid-Atlantic - Old",
        "value": "(UTC-02:00) Mid-Atlantic - Old"
    },
    {
        "title": "(UTC-01:00) Azores",
        "value": "(UTC-01:00) Azores"
    },
    {
        "title": "(UTC-01:00) Cape Verde Is.",
        "value": "(UTC-01:00) Cape Verde Is."
    },
    {
        "title": "(UTC) Casablanca",
        "value": "(UTC) Casablanca"
    },
    {
        "title": "(UTC) Coordinated Universal Time",
        "value": "(UTC) Coordinated Universal Time"
    },
    {
        "title": "(UTC) Edinburgh, London",
        "value": "(UTC) Edinburgh, London"
    },
    {
        "title": "(UTC+01:00) Edinburgh, London",
        "value": "(UTC+01:00) Edinburgh, London"
    },
    {
        "title": "(UTC) Dublin, Lisbon",
        "value": "(UTC) Dublin, Lisbon"
    },
    {
        "title": "(UTC) Monrovia, Reykjavik",
        "value": "(UTC) Monrovia, Reykjavik"
    },
    {
        "title": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "value": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "title": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        "value": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
        "title": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        "value": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        "title": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        "value": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        "title": "(UTC+01:00) West Central Africa",
        "value": "(UTC+01:00) West Central Africa"
    },
    {
        "title": "(UTC+01:00) Windhoek",
        "value": "(UTC+01:00) Windhoek"
    },
    {
        "title": "(UTC+02:00) Athens, Bucharest",
        "value": "(UTC+02:00) Athens, Bucharest"
    },
    {
        "title": "(UTC+02:00) Beirut",
        "value": "(UTC+02:00) Beirut"
    },
    {
        "title": "(UTC+02:00) Cairo",
        "value": "(UTC+02:00) Cairo"
    },
    {
        "title": "(UTC+02:00) Damascus",
        "value": "(UTC+02:00) Damascus"
    },
    {
        "title": "(UTC+02:00) E. Europe",
        "value": "(UTC+02:00) E. Europe"
    },
    {
        "title": "(UTC+02:00) Harare, Pretoria",
        "value": "(UTC+02:00) Harare, Pretoria"
    },
    {
        "title": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "value": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
        "title": "(UTC+03:00) Istanbul",
        "value": "(UTC+03:00) Istanbul"
    },
    {
        "title": "(UTC+02:00) Jerusalem",
        "value": "(UTC+02:00) Jerusalem"
    },
    {
        "title": "(UTC+02:00) Tripoli",
        "value": "(UTC+02:00) Tripoli"
    },
    {
        "title": "(UTC+03:00) Amman",
        "value": "(UTC+03:00) Amman"
    },
    {
        "title": "(UTC+03:00) Baghdad",
        "value": "(UTC+03:00) Baghdad"
    },
    {
        "title": "(UTC+03:00) Kaliningrad, Minsk",
        "value": "(UTC+03:00) Kaliningrad, Minsk"
    },
    {
        "title": "(UTC+03:00) Kuwait, Riyadh",
        "value": "(UTC+03:00) Kuwait, Riyadh"
    },
    {
        "title": "(UTC+03:00) Nairobi",
        "value": "(UTC+03:00) Nairobi"
    },
    {
        "title": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
        "value": "(UTC+03:00) Moscow, St. Petersburg, Volgograd"
    },
    {
        "title": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
        "value": "(UTC+04:00) Samara, Ulyanovsk, Saratov"
    },
    {
        "title": "(UTC+03:30) Tehran",
        "value": "(UTC+03:30) Tehran"
    },
    {
        "title": "(UTC+04:00) Abu Dhabi, Muscat",
        "value": "(UTC+04:00) Abu Dhabi, Muscat"
    },
    {
        "title": "(UTC+04:00) Baku",
        "value": "(UTC+04:00) Baku"
    },
    {
        "title": "(UTC+04:00) Port Louis",
        "value": "(UTC+04:00) Port Louis"
    },
    {
        "title": "(UTC+04:00) Tbilisi",
        "value": "(UTC+04:00) Tbilisi"
    },
    {
        "title": "(UTC+04:00) Yerevan",
        "value": "(UTC+04:00) Yerevan"
    },
    {
        "title": "(UTC+04:30) Kabul",
        "value": "(UTC+04:30) Kabul"
    },
    {
        "title": "(UTC+05:00) Ashgabat, Tashkent",
        "value": "(UTC+05:00) Ashgabat, Tashkent"
    },
    {
        "title": "(UTC+05:00) Yekaterinburg",
        "value": "(UTC+05:00) Yekaterinburg"
    },
    {
        "title": "(UTC+05:00) Islamabad, Karachi",
        "value": "(UTC+05:00) Islamabad, Karachi"
    },
    {
        "title": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "value": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        "title": "(UTC+05:30) Sri Jayawardenepura",
        "value": "(UTC+05:30) Sri Jayawardenepura"
    },
    {
        "title": "(UTC+05:45) Kathmandu",
        "value": "(UTC+05:45) Kathmandu"
    },
    {
        "title": "(UTC+06:00) Astana",
        "value": "(UTC+06:00) Astana"
    },
    {
        "title": "(UTC+06:00) Dhaka",
        "value": "(UTC+06:00) Dhaka"
    },
    {
        "title": "(UTC+06:30) Yangon (Rangoon)",
        "value": "(UTC+06:30) Yangon (Rangoon)"
    },
    {
        "title": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        "value": "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
        "title": "(UTC+07:00) Novosibirsk",
        "value": "(UTC+07:00) Novosibirsk"
    },
    {
        "title": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "value": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
        "title": "(UTC+08:00) Krasnoyarsk",
        "value": "(UTC+08:00) Krasnoyarsk"
    },
    {
        "title": "(UTC+08:00) Kuala Lumpur, Singapore",
        "value": "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    {
        "title": "(UTC+08:00) Perth",
        "value": "(UTC+08:00) Perth"
    },
    {
        "title": "(UTC+08:00) Taipei",
        "value": "(UTC+08:00) Taipei"
    },
    {
        "title": "(UTC+08:00) Ulaanbaatar",
        "value": "(UTC+08:00) Ulaanbaatar"
    },
    {
        "title": "(UTC+08:00) Irkutsk",
        "value": "(UTC+08:00) Irkutsk"
    },
    {
        "title": "(UTC+09:00) Osaka, Sapporo, Tokyo",
        "value": "(UTC+09:00) Osaka, Sapporo, Tokyo"
    },
    {
        "title": "(UTC+09:00) Seoul",
        "value": "(UTC+09:00) Seoul"
    },
    {
        "title": "(UTC+09:30) Adelaide",
        "value": "(UTC+09:30) Adelaide"
    },
    {
        "title": "(UTC+09:30) Darwin",
        "value": "(UTC+09:30) Darwin"
    },
    {
        "title": "(UTC+10:00) Brisbane",
        "value": "(UTC+10:00) Brisbane"
    },
    {
        "title": "(UTC+10:00) Canberra, Melbourne, Sydney",
        "value": "(UTC+10:00) Canberra, Melbourne, Sydney"
    },
    {
        "title": "(UTC+10:00) Guam, Port Moresby",
        "value": "(UTC+10:00) Guam, Port Moresby"
    },
    {
        "title": "(UTC+10:00) Hobart",
        "value": "(UTC+10:00) Hobart"
    },
    {
        "title": "(UTC+09:00) Yakutsk",
        "value": "(UTC+09:00) Yakutsk"
    },
    {
        "title": "(UTC+11:00) Solomon Is., New Caledonia",
        "value": "(UTC+11:00) Solomon Is., New Caledonia"
    },
    {
        "title": "(UTC+11:00) Vladivostok",
        "value": "(UTC+11:00) Vladivostok"
    },
    {
        "title": "(UTC+12:00) Auckland, Wellington",
        "value": "(UTC+12:00) Auckland, Wellington"
    },
    {
        "title": "(UTC+12:00) Coordinated Universal Time+12",
        "value": "(UTC+12:00) Coordinated Universal Time+12"
    },
    {
        "title": "(UTC+12:00) Fiji",
        "value": "(UTC+12:00) Fiji"
    },
    {
        "title": "(UTC+12:00) Magadan",
        "value": "(UTC+12:00) Magadan"
    },
    {
        "title": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
        "value": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
    },
    {
        "title": "(UTC+13:00) Nuku'alofa",
        "value": "(UTC+13:00) Nuku'alofa"
    },
    {
        "title": "(UTC+13:00) Samoa",
        "value": "(UTC+13:00) Samoa"
    }
]
