import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListTags, createTag, removeOrderTag} from '../../../services/api/OrdersServices'

class OrderTags extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            tags: [],
            newTag: '',
            err: ''
        }
    }

    componentDidMount() {
        this._fetchListTags()
    }

    _fetchListTags = async () => {
        const {orderId} = this.state
        const {success, data, message} = await getListTags(orderId)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            tags: data
        })
    }

    _handleTag = (e) => {
        console.log(e.target.value)
        this.setState({
            newTag: e.target.value
        })
    }

    _addTag = async (e) => {
        if (e.key !== 'Enter') {
            return
        }
        const {newTag, orderId} = this.state
        const {success, data, message} = await createTag(orderId, newTag)

        if (!success) {
            return alert(message)
        }

        this.setState((state) => {
            return {
                newTag: '',
                tags: [...state.tags, data]
            }
        })
    }

    _deleteTag = async (tag) => {
        const {orderId} = this.state
        const {success, message} = await removeOrderTag(orderId, tag._id)

        if (!success) {
            return alert(message)
        }
        this.setState((state) => {
            return {
                tags: state.tags.filter((t) => {
                    return tag._id !== t._id
                })
            }
        })
    }

    render() {
        const {tags, err, newTag} = this.state

        return (
            <div className="OrderTags">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        Tags
                    </div>
                    <div className="card-body">
                        <input type="text" className="form-control"
                               placeholder="Reviewed, Packed, delivered"
                               value={newTag}
                               onChange={(e) => this._handleTag(e)}
                               onKeyPress={(e) => this._addTag(e)}
                        />
                        {err ? <div>{err} </div> :
                            <ul className='ListTags mt-2'>
                                {
                                    tags.map((tag, i) => {
                                        return (
                                            <li className="Tag mr-2" key={i}>
                                                <span className="text-dark">{tag.title}</span>
                                                <span className="text-muted Close"
                                                      onClick={() => this._deleteTag(tag)}>x</span>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

OrderTags.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default OrderTags
