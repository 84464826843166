import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/seller' : 'https://pro.merch8.com/api/seller'
const api = createAPIServices({baseUrl})

export const getListStores = () => {
    return api.makeAuthRequest({
        url: '/stores',
        method: 'GET'
    })
}

export const getListPendingStores = () => {
    return api.makeAuthRequest({
        url: '/stores/pending',
        method: 'GET'
    })
}

export const createStore = (data = {}) => {
    return api.makeAuthRequest({
        url: '/stores',
        method: 'POST',
        data
    })
}

export const fetchStoreSettingsBySlug = slug => {
    return api.makeAuthRequest({
        url: `/stores/by-slug/${slug}/settings`,
        method: 'GET',
    })
}

