import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {revokeCredential} from "../../../services/api/GSSIntegrationServices"

class RevokeButton extends Component {
    _handleClick = (e) => {
        e.preventDefault()

        const r = window.confirm('Are you sure want to revoke current account?')
        if (!r) return

        this._revoke()
    }

    _revoke = async () => {
        try {
            const {success, message} = await revokeCredential()

            if (!success) {
                return window.alert(message)
            }

            this.props.onSuccess(true)
        } catch (e) {
            window.alert(e.message)
        }
    }

    render() {
        return (
            <button onClick={this._handleClick} className="btn btn-link RevokeButton">
                Revoke
            </button>
        )
    }
}

RevokeButton.propTypes = {
    onSuccess: PropTypes.func.isRequired,
}

export default RevokeButton
