import React, {Component, Fragment} from "react"
import SettingsPage from "./SettingsPage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

class SettingsPageContainer extends Component {
    render() {
        return (
            <Fragment>
                <DocTitleByStore title="Settings"/>
                <SettingsPage {...this.props}/>
            </Fragment>
        )
    }
}

export default SettingsPageContainer
