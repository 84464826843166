import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import WithStoreSettings from "../../../shared/WithStoreSettings"
import humanizeTime from "../../../../helpers/time/humanizeTime"

class DateProductColumn extends Component {
    _validateDate = () => {
        const {item, store} = this.props
        const {created, updated} = Object.assign({}, item)

        const timezone = store.getTimezone()
        const momentTime = moment(updated || created).utcOffset(timezone)

        return humanizeTime(momentTime)
    }

    render() {
        const time = this._validateDate()

        return (
            <td>
                <div>{time}</div>
            </td>
        )
    }
}

DateProductColumn.propTypes = {
    store: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {store => {
                return (
                    <DateProductColumn store={store} {...props}/>
                )
            }}
        </WithStoreSettings>
    )
}
