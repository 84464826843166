import React, {Component} from "react"
import PropTypes from "prop-types"
import SettingInput from "../../setting/shared/SettingInput"
import SettingCheckbox from "../../setting/shared/SettingCheckbox"

class ProductInfo extends Component {
    _changeSettings = (key) => (value) => {
        this.props.onChange(key, value)
    }

    _changeCheckSetting = (key) => (value) => {
        this.props.onChange(key, (value) ? "enabled" : "disabled")
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="ProductInfo">
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            itemId="shipping_info"
                            onChange={this._changeCheckSetting("shipping_info")}
                            label="Enable shipping info on Product page"
                            checked={(form['shipping_info'] === 'enabled')}
                            disabled={loading.fetchSettings}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            itemId="show_featured_collections"
                            onChange={this._changeCheckSetting("show_featured_collections")}
                            label="Show featured collections on Home page"
                            checked={(form['show_featured_collections'] === 'enabled')}
                            disabled={loading.fetchSettings}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            itemId="is_co_countdown_show"
                            onChange={this._changeCheckSetting("is_co_countdown_show")}
                            label="Enable countdown on Checkout page"
                            checked={(form['is_co_countdown_show'] === 'enabled')}
                            disabled={loading.fetchSettings}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            itemId="is_product_countdown_show"
                            onChange={this._changeCheckSetting("is_product_countdown_show")}
                            label="Enable countdown on Product page"
                            checked={(form['is_product_countdown_show'] === 'enabled')}
                            disabled={loading.fetchSettings}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            itemId="show_track_order"
                            onChange={this._changeCheckSetting("show_track_order")}
                            label="Display “Track Order” page on menu"
                            checked={(form['show_track_order'] === 'enabled')}
                            disabled={loading.fetchSettings}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            itemId="happy_customer_uri"
                            type="url"
                            onChange={this._changeSettings('happy_customer_uri')}
                            label="Display Testimony page on menu and load the following URL"
                            disabled={loading.fetchSettings}
                            value={form['happy_customer_uri'] || ''}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            itemId="discount_value"
                            type="number"
                            min="0"
                            onChange={this._changeSettings('discount_value')}
                            label="The amount of discount that you wanted your customers to see for each product"
                            disabled={loading.fetchSettings}
                            value={form['discount_value'] || ''}
                            suffix="%"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ProductInfo.propTypes = {
    loading: PropTypes.object.isRequired,
    form: PropTypes.object,
    onChange: PropTypes.func,
}

export default ProductInfo
