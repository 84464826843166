import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import WithStoreSettings from "../../shared/WithStoreSettings"

class DocTitleByStore extends Component {
    render() {
        const {title} = this.props

        return (
            <WithStoreSettings>
                {
                    store => {
                        const name = store.getSetting('name', 'Store Name')

                        return (
                            <Helmet>
                                <title>{title} - {name}</title>
                            </Helmet>
                        )
                    }
                }
            </WithStoreSettings>

        )
    }
}

DocTitleByStore.propTypes = {
    title: PropTypes.string.isRequired,
}

export default DocTitleByStore
