import React, {Component} from 'react'
import PropTypes from 'prop-types'

const prefixUrl = 'http://href.li/'

class SourceColumn extends Component {
    render() {
        const {item} = this.props
        const {url} = Object.assign({}, item)
        const validatedUrl = (url + '').trim()
        const href = `${prefixUrl}${validatedUrl}`
        const shortedURL = (validatedUrl.length > 50) ? validatedUrl.slice(0, 50) + '...' : url

        return (
            <td className="SourceColumn">
                <a target='_blank' rel='noopener noreferrer' href={href}>
                    {shortedURL}
                </a>
            </td>
        )
    }
}

SourceColumn.propTypes = {
    item: PropTypes.object.isRequired
}

export default SourceColumn
