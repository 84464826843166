import React, {Component} from 'react'
import NumericInput from 'react-numeric-input'
import PropTypes from 'prop-types'
import {getListUnfulfilledItems} from "../../../services/api/OrdersServices"

class FulfillQuantity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            items: [],
            itemsQuantity: []
        }
    }

    componentDidMount() {
        this._getItems()
    }

    _getItems = async () => {
        const {orderId} = this.state
        const {success, data, message} = await getListUnfulfilledItems(orderId)

        if (!success) {
            return this.setState({
                loadingItems: false,
                messageItems: message
            })
        }

        let itemsQuantity = []
        data.forEach((item, i) => {
            itemsQuantity[i] = item.quantity
        })
        this.setState({
            loadingItems: false,
            items: data,
            itemsQuantity: itemsQuantity
        })
    }

    _handleQuantity = (e, i) => {
        let {itemsQuantity, items} = this.state
        itemsQuantity[i] = e >= 0 && e <= items[i].quantity ? e : itemsQuantity[i]

        this.setState({
            itemsQuantity: itemsQuantity
        })
    }

    render() {
        const {items} = this.state

        return (
            <div className="QuantityFulfill card-body">
                <p className="font-weight-bold text-dark">Quantity to fulfill</p>
                <table className="container-fluid">
                    <tbody>
                    <tr className="row">
                        <td className="col-9">Items</td>
                        <td className="col-3">Quantity</td>
                    </tr>
                    {
                        items.map((item, i) => {
                            return (
                                <tr className="row" key={item._id}>
                                    <td className="col-9">
                                        <div className="row">
                                            <div className="col-3">
                                                <img src={item.image} alt="NF!" className="img-fluid"/>
                                            </div>
                                            <div className="ItemInfo col-9 card-text text-muted">
                                                <span className="text-primary">
                                                    <a href={item.url} target="_blank"
                                                       rel="noopener noreferrer">{item.product.title}</a>
                                                </span><br/>
                                                <span>Item: #{item._id}</span><br/>
                                                <span>{item.variant.title || ''}</span><br/>
                                                {
                                                    item.product.type ? <span
                                                        className="badge badge-primary"> {item.product.type.type} - {item.product.type.vendor}</span> : ''
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td className="col-3 d-flex">
                                        <div className="input-group m-auto">
                                            <NumericInput className="NumericInput"
                                                          value={this.state.itemsQuantity[i]}
                                                          min={0} max={item.quantity}
                                                          onChange={(e) => this._handleQuantity(e, i)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-white border-0"> of {item.quantity}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

FulfillQuantity.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default FulfillQuantity
