import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TotalOrderColumn extends Component {
    render() {
        const {nameColumn, order} = this.props

        return (
            <td key={nameColumn}>
                {(order.items) ? order.items.reduce((accumulator, itm) => accumulator + itm.quantity, 0) : 0}
            </td>
        )
    }
}

TotalOrderColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired
}

export default TotalOrderColumn
