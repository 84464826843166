import React, {Component} from 'react'
import ImporterForm from './ImporterForm'
import BulkImporterForm from "./BulkImporterForm"

class ImporterFormContainer extends Component {
    render() {
        return (
            <div className='ImporterFormContainer'>
                <div className='container-fluid'>
                    <ImporterForm/>
                    <hr className="mt-2"/>
                    <BulkImporterForm/>
                </div>
            </div>
        )
    }
}

export default ImporterFormContainer
