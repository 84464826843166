import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderRevenueColumn extends Component {
    render() {
        const {order: {invoice}} = this.props
        const {amount} = Object.assign({}, invoice)
        const amountValidated = parseFloat(amount || 0)

        return (
            <td className="OrderRevenueColumn">
                <div>${amountValidated}</div>
            </td>
        )
    }
}

OrderRevenueColumn.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderRevenueColumn
