import React, {Component} from 'react'
import ListStores from "./ListStores"
import CreateStoreForm from "./CreateStoreForm"
import ListPendingStores from "./ListPendingStores"
import {Link} from "react-router-dom"

class StoresPage extends Component {
    render() {
        return (
            <div className="StoresPage">
                <div className="container StoresPageInner">
                    <div className="Top d-flex justify-content-between align-items-center">
                        <h1 className="PageTitle">Stores</h1>
                        <Link to="/logout">Logout</Link>
                    </div>

                    <CreateStoreForm/>
                    <ListStores/>
                    <ListPendingStores/>
                </div>
            </div>
        )
    }
}

export default StoresPage
