import React, {Component} from "react"
import {settings} from '../../../statics/settings'

const menus = settings

class SettingsPage extends Component {
    _pushPage = (index) => e => {
        e.preventDefault()
        if (menus[index]) return this.props.history.push(menus[index].location)
    }

    render() {
        return (
            <section className="SettingsContainer">
                <h1 className="PageTitle">Settings</h1>

                <div className="SectionInner">
                    <div className="card-body Card">
                        <div className="SettingsWrapper">
                            {settings.map((setting, index) =>
                                <div className="SettingWrapper" key={index} onClick={this._pushPage(index)}>
                                    <div className="IconWrapper">
                                        <i className={setting.icon}/>
                                    </div>
                                    <div className="Info">
                                        <h6 className="Title">{setting.title}</h6>
                                        <div className="Description">{setting.description}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SettingsPage
