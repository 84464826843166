import React, {Component} from 'react'
import PropTypes from 'prop-types'

const statusSelection = [
    {
        title: 'All',
        value: 'all',
    },
    {
        title: 'Paid',
        value: 'paid'
    },
    {
        title: "Refunded",
        value: 'refunded'
    }
]

class OrdersFilter extends Component {
    state = {
        status: 'paid'
    }

    _handleFilter = (e) => {
        statusSelection.forEach((st, index) => {
            statusSelection[index].select = e === st.value
        })

        this.setState({
            status: e
        }, () => {
            this.props.changeFilter((e === 'all') ? null : e)
        })
    }

    render() {
        const {status} = this.state

        return (
            <div className="OrdersFilter">
                <div className="LabelFilter PaymentStatus d-flex">
                    <label className="FilterName">Payment Status:</label>
                    <ul className="ListOrder FilterItems" role="tablist">
                        {statusSelection.map((st) => {
                            return (
                                <li className={(status === st.value) ? 'active' : ''}
                                    onClick={() => this._handleFilter(st.value)}
                                    key={st.value}>
                                    <span className="nav-link" id="pills-home-tab" data-toggle="pill"
                                          role="tab" aria-controls="pills-home" aria-selected="true">{st.title}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

OrdersFilter.propTypes = {
    changeFilter: PropTypes.func.isRequired,
}

export default OrdersFilter
