import React, {Component} from "react"
import SettingSectionDetails from "../../setting/shared/SettingSectionDetails"
import StoreDetails from "./StoreDetails"
import StoreAddress from "./StoreAddress"
import StandardFormat from "./StandardFormat"
import StoreCurrency from "./StoreCurrency"
import StoreAppearance from "./StoreAppearance"
import classNames from 'classnames'
import {getSettingsByCategory, saveSettingsByCategory} from "../../../services/api/SettingServices"
import {refreshStoreSettings} from "../../../services/CurrentStoreService"

class SettingGeneralContainer extends Component {
    state = {
        form: {
            'name': {value: ''},
            'tag_line': {value: ''},
            'email_support': {value: ''},
            'logo_url': {value: ''},
            'favicon_url': {value: ''},
            'banner_url': {value: ''},
            'legal_name': {value: ''},
            'phone': {value: ''},
            'address': {value: ''},
            'city': {value: ''},
            'zip_code': {value: ''},
            'country': {value: ''},
            'state': {value: ''},
            'timezone': {value: ''},
            'currency': {value: ''},
        },
        loading: {
            fetchSettings: false,
            spin: false,
            saveSuccess: false,
        }
    }

    componentDidMount() {
        this._getSettings()
    }

    _getSettings = async () => {
        this.setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                fetchSettings: true,
            }
        }))
        const {success, data} = await getSettingsByCategory('general')
        if (success) {
            this.setState(state => ({
                form: {
                    ...state.form,
                    ...data.reduce((accumulator, setting) => ({
                        ...accumulator,
                        [setting.key]: setting
                    }), {}),
                },
                loading: {
                    fetchSettings: false,
                    spin: false,
                }
            }))
        } else {
            this.setState(state => ({
                loading: {
                    fetchSettings: false,
                    spin: false,
                }
            }))
        }
    }

    _onChangeSetting = (key, value) => {
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [key]: {
                    ...state.form[key],
                    value
                },
            }
        }))
    }

    _clickSaveButton = () => {
        this._saveSettings()
    }

    _saveSettings = async () => {
        const {form} = this.state
        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: true,
            }
        }))
        const {success} = await saveSettingsByCategory('general', Object.keys(form).map((key) => ({
            key,
            value: form[key].value
        })))

        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: false,
                saveSuccess: success,
            }
        }))

        await refreshStoreSettings()
    }

    render() {
        const {loading, form} = this.state
        const {_onChangeSetting} = this
        const spin = loading.spin

        return (
            <div className="SettingsContainer">
                <div className="Section">
                    <SettingSectionDetails
                        title="Store Details"
                        description="We and your customers will use this information to contact you."
                    />
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <StoreDetails
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Section">
                    <SettingSectionDetails
                        title="Store appearance"
                        description=""
                    />
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <StoreAppearance
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Section">
                    <SettingSectionDetails
                        title="Store address"
                        description="This address will appear on your invoices. You can edit the address used to calculate shipping rates in your shipping settings"
                    />
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <StoreAddress
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Section">
                    <SettingSectionDetails
                        title="Standards and formats"
                        description="Standards and formats are used to calculate product prices, shipping weights, and order times."
                    />
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <StandardFormat
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Section">
                    <SettingSectionDetails
                        title="Store currency"
                        description="Customers use this currency to buy your products. It can only be changed before you've made your first sale."
                    />
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <StoreCurrency
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ButtonSave">
                    <button
                        className="btn btn-primary"
                        onClick={this._clickSaveButton}
                        disabled={loading.spin}
                    >
                        <span className="Label">Save</span>
                        <span className={classNames("Spin", {spin})}/>
                    </button>
                </div>

            </div>
        )
    }
}

export default SettingGeneralContainer

