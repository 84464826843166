import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({suffix: '/order'})

export const getOrderStatistics = (data = {}) => {
    return api.makeAuthRequest({
        url: '/orders/statistics',
        method: 'POST',
        data: data
    })
}

export const searchOrders = (data = {}) => {
    return api.makeAuthRequest({
        url: '/orders/search/v2',
        method: 'POST',
        data
    })
}

export const getOrder = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}`,
        method: 'GET'
    })
}

export const getOrderHistories = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/histories`,
        method: 'GET'
    })
}

export const getOrderNote = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/notes`,
        method: 'GET'
    })
}

export const getOrderInvoice = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/invoice`,
        method: 'GET'
    })
}

export const getOrderBuyerDetail = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/buyerdetails`,
        method: 'GET'
    })
}

export const getItemsByOrderId = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/items`,
        method: 'GET'
    })
}

export const changeOrderStatus = ({orderId = '', status = '',}) => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/status`,
        data: {
            status,
        },
        method: 'POST'
    })
}

export const saveOrdersNotes = ({orderId = '', supportNote = '', fulfillmentNote = '',}) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/notes`,
        data: {
            support_note: supportNote,
            fulfillment_note: fulfillmentNote,
        }
    })
}

export const getListUnfulfilledItems = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/unfulfilled`,
        method: 'GET'
    })
}

export const getListFulfillments = (orderId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments`,
        method: 'GET'
    })
}

export const getListFulfilledItems = (orderId = '', fulfillId = '') => {
    return api.makeAuthRequest({
        url: `/orders/${orderId}/fulfillments/${fulfillId}/items`,
        method: 'GET'
    })
}

export const createFulfillment = ({orderId = '', tracking_number = '', tracking_url = '', tracking_company = '', notify_customer = true, items = []}) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments`,
        data: {
            tracking_number: tracking_number,
            tracking_company: tracking_company,
            tracking_url: tracking_url,
            notify_customer: notify_customer,
            items: items
        }
    })
}

export const cancelFulfilled = (orderId = '', fulfillId = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments/${fulfillId}/cancel`
    })
}

export const editTracking = (orderId = '', fulfillId = '', data) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/fulfillments/${fulfillId}/tracking`,
        data: data
    })
}

export const editAddress = (orderId = '', addressId = '', data) => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/address/${addressId}`,
        data: data
    })
}

export const getListTags = (orderId = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'GET',
        url: `/orders/${orderId}/tags`
    })
}

export const createTag = (orderId = '', title = '') => {
    if (!orderId) return null
    return api.makeAuthRequest({
        method: 'POST',
        url: `/orders/${orderId}/tags`,
        data: {
            title: title
        }
    })
}

export const removeOrderTag = async (orderId = '', tagId = '') => {
    if (!orderId || !tagId) return null

    return api.makeAuthRequest({
        method: 'DELETE',
        url: `/orders/${orderId}/tags/${tagId}`
    })
}
