import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IndexColumn extends Component {
    render() {
        const {order} = this.props

        return (
            <td className="IndexColumn">
                <span>{order || ''}</span>
            </td>

        )
    }
}

IndexColumn.propTypes = {
    order: PropTypes.number.isRequired,
}

export default IndexColumn
