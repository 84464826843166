import React, {Component} from "react"
import PropTypes from "prop-types"

class SettingSelect extends Component {
    componentDidMount() {
        const {value, options, onChange} = this.props
        if (!value && onChange) return onChange(options[0].value)
    }

    _selectOption = (e) => {
        const {onChange} = this.props
        const {value} = e.target

        return onChange(value)
    }

    render() {
        const {label, itemId, value, options, disabled} = this.props
        const selectProps = {
            id: itemId,
            disabled,
            value,
        }

        return (
            <div className="SettingSelect">
                <div className="form-group">
                    {(label) ? <label htmlFor={itemId}>{label}</label> : null}
                    <select className="custom-select my-1 mr-sm-2" {...selectProps} onChange={this._selectOption}>
                        {(options) ? options.map((option, id) => <option key={id} value={option.value}>
                            {option.title}
                        </option>) : null}
                    </select>
                </div>
            </div>
        )
    }
}

SettingSelect.propTypes = {
    label: PropTypes.string,
    itemId: PropTypes.string.isRequired,
    options: PropTypes.array,
    onChange: PropTypes.func,
}

export default SettingSelect
