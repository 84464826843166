import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const timeSelection = [
    {
        title: "Last day",
        value: '1-day-ago'
    },
    {
        title: "Last 2 days",
        value: '2-days-ago'
    },
    {
        title: "Last 7 days",
        value: '7-days-ago',
    },
    {
        title: 'Last month',
        value: '1-month-ago'
    },
    {
        title: 'All',
        value: 'all',
    },
]

class StatisticsFilter extends Component {
    _handleFilter = (selected) => (e) => {
        e.preventDefault()

        this.props.onChangeQuery((query) => {
            return {
                ...query,
                created: {
                    from: selected
                }
            }
        })
    }

    _getFromDate = () => {
        const {query} = this.props
        const {created} = Object.assign({}, query)
        const {from} = Object.assign({}, created)

        return from || ''
    }

    render() {
        const from = this._getFromDate()

        return (
            <div className="StatisticsFilter LabelFilter">
                <label>Statistics filter</label>
                <ul className="FilterItems" role="tablist">
                    {timeSelection.map((time) => {
                        const {value, title} = Object.assign({}, time)
                        const active = from === value

                        return (
                            <li className={classNames({active})} onClick={this._handleFilter(value)} key={time.value}>
                                    <span className="nav-link" id="pills-home-tab" data-toggle="pill" role="tab"
                                          aria-controls="pills-home" aria-selected="true">
                                        {title}
                                    </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

StatisticsFilter.propTypes = {
    query: PropTypes.object.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
}

export default StatisticsFilter
