import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TableHeadImporter from "./TableHeadImporter"
import TableBodyImporter from "./TableBodyImporter"

class TableImporterJobs extends Component {
    render() {
        return (
            <div className="TableImporterJobs">
                <table className="table table-striped table-hover">
                    <TableHeadImporter {...this.props}/>
                    <TableBodyImporter {...this.props}/>
                </table>
            </div>
        )
    }
}

TableImporterJobs.propTypes = {
    items: PropTypes.array.isRequired,
}

export default TableImporterJobs
