import createAPIServices from "./createApiServices"
import {getCurrentStore, getStoreSetting, subscribeChangeStore} from "../CurrentStoreService"

const _getDefaultBaseURL = (suffix = '') => {
    const prefixURL = process.env.NODE_ENV === 'production' ? '/api/stores' : 'https://pro.merch8.com/api/stores'
    const currentStore = getCurrentStore()

    return `${prefixURL}/${currentStore}${suffix}`
}

const _create = (options = {}) => {
    const {suffix, getBaseURL} = Object.assign({}, options)

    const _getBaseURLByStore = () => {
        const currentStore = getCurrentStore()
        const isProduction = process.env.NODE_ENV === 'production'

        return typeof getBaseURL === 'function' ? getBaseURL(currentStore, isProduction, getStoreSetting) : _getDefaultBaseURL(suffix)
    }

    const _api = createAPIServices({baseUrl: _getBaseURLByStore()})

    subscribeChangeStore(() => {
        _api.changeBaseURL(_getBaseURLByStore())
    })

    return _api
}

const _validateOptions = (options) => {
    const {suffix, getBaseURL} = Object.assign({}, options)

    return {
        suffix: suffix || '',
        getBaseURL,
    }
}

export const createStoreAPI = (options = {}) => {
    const opts = _validateOptions(options)

    return _create(opts)
}

