import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {fetchOrderStatisticsOverview} from "../../../../services/api/NewStatisticsServices"
import StatisticBox from "./StatisticBox"
import PubSub from "../../PubSub"

class OrderStatisticsOverview extends Component {
    state = {
        statistics: {},
        loading: false,
        error: ''
    }

    _refresh = null
    _changeQuery = null

    componentDidMount() {
        this._setupChangeQuery()
        this._setUpRefresh()
        this._fetchStatistics()
    }

    componentWillUnmount() {
        this._destroyChangeQuery()
        this._destroyRefresh()
    }

    _setupChangeQuery = () => {
        this._changeQuery = PubSub.subscribe('STATISTICS_FILTER_UPDATED', () => {
            this._fetchStatistics()
        })
    }

    _destroyChangeQuery = () => {
        this._changeQuery && PubSub.unsubscribe(this._changeQuery)
    }

    _setUpRefresh = () => {
        if (this._refresh) clearInterval(this._refresh)

        this._refresh = setInterval(() => {
            this._fetchStatistics()
        }, 10000)
    }

    _destroyRefresh = () => {
        this._refresh && clearInterval(this._refresh)
    }

    _fetchStatistics = async () => {
        if (this.state.loading) return false

        this.setState({
            loading: true,
            error: ''
        })

        const {query: {created}} = this.props

        try {
            const {success, data, message} = await fetchOrderStatisticsOverview({created})

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            this.setState({
                statistics: Object.assign({}, data),
                loading: false,
                error: ''
            })
        } catch (e) {
            this.setState({
                error: e.message || e,
                loading: false
            })
        }
    }

    _getDataFirstBox = () => {
        const {statistics} = this.state
        const {sales, total, salesPerOrder, conversionRatePercent} = Object.assign({}, statistics)

        return [
            {
                key: 'ordersAndSales',
                title: 'Orders/Sales',
                value: `${total || 0}/${sales || 0}`
            },
            {
                key: 'salesPerOrder',
                title: 'Sales/Order',
                value: salesPerOrder || 0,
            },
            {
                key: 'conversionRate',
                title: 'Conversion rate',
                value: conversionRatePercent || 0,
            }
        ]
    }

    _getDataSecondBox = () => {
        const {statistics} = this.state
        const {revenue, averageOrderValue} = Object.assign({}, statistics)

        return [
            {
                key: 'revenue',
                title: 'Revenue',
                value: '$' + (revenue || 0),
            },
            {
                key: 'averageOrderValue',
                title: 'Average order value',
                value: '$' + (averageOrderValue || 0),
            },
        ]
    }

    render() {
        const {loading, error} = this.state
        const firstBox = this._getDataFirstBox()
        const secondBox = this._getDataSecondBox()

        return (
            <div className={classNames("OrderStatisticsOverview col-md-8 col-12", {loading})}>
                {
                    !!error &&
                    <div className="Error">{error}</div>
                }

                <div className="StatisticsRow card-deck row">
                    <StatisticBox className="col-md-6 col-12" statistics={firstBox}/>
                    <StatisticBox className="col-md-6 col-12" statistics={secondBox}/>
                </div>
            </div>
        )
    }
}

OrderStatisticsOverview.propTypes = {
    query: PropTypes.object.isRequired,
}

export default OrderStatisticsOverview
