import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({
    getBaseURL: (currentStore, isProduction = false) => {
        return isProduction ? `/api/seller/stores/${currentStore}/settings` : `https://pro.merch8.com/api/seller/stores/${currentStore}/settings`
    }
})

export const getSettingsByCategory = (category) => {
    return api.makeAuthRequest({
        url: `/${category}`,
        method: 'GET'
    })
}

export const saveSettingsByCategory = (category, settings) => {
    return api.makeAuthRequest({
        url: `/${category}`,
        method: 'POST',
        data: {
            settings
        }
    })
}
