import React, {Component} from 'react'
import {getAccountInformation} from "../../../services/api/GSSIntegrationServices"

class AccountInfo extends Component {
    state = {
        account: {
            email: '',
            displayName: ''
        },
        error: '',
        loading: false
    }

    componentDidMount() {
        this._fetchAccountInfo()
    }

    _fetchAccountInfo = async () => {
        try {
            const {success, data, message} = await getAccountInformation()
            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            this.setState({
                loading: false,
                error: '',
                account: Object.assign({}, data)
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    render() {
        const {account: {email, displayName}, loading, error} = this.state

        return (
            <div className="AccountInfo">
                {
                    !!error &&
                    <div className="Error">{error}</div>
                }
                {
                    !!loading &&
                    <div className="Loading">Loading...</div>
                }
                <div className="Inner">
                    {
                        !!email &&
                        <div className="Text">Current account: {displayName} ({email})</div>
                    }
                </div>
            </div>
        )
    }
}

export default AccountInfo
