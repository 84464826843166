import React, {Component} from "react"
import SettingInput from "../../setting/shared/SettingInput"
import PropTypes from "prop-types"

class FacebookTracking extends Component {
    _changeSettings = (key) => value => {
        return this.props.onChange(key, value)
    }

    render() {
        const {loading, form} = this.props

        return (
            <div className="FacebookTracking">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Facebook Pixel"
                            itemId="fbpxl"
                            disabled={loading.fetchSettings}
                            value={form['fbpxl'] || ''}
                            onChange={this._changeSettings('fbpxl')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FacebookTracking.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default FacebookTracking
