import React, {Component} from "react"
import PropTypes from "prop-types"

import SettingInput from "../../setting/shared/SettingInput"

class StoreDetails extends Component {
    _changeSettings = (key) => (value) => {
        this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="StoreDetails">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Store name"
                            itemId="name"
                            disabled={loading.fetchSettings}
                            value={form['name'].value || ''}
                            onChange={this._changeSettings('name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Tag line"
                            itemId="tag_line"
                            disabled={loading.fetchSettings}
                            value={form['tag_line'].value || ''}
                            onChange={this._changeSettings('tag_line')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Support email"
                            type="email"
                            smallText="Your customers will see this address if you email them."
                            itemId="email"
                            disabled={loading.fetchSettings}
                            value={form['email_support'].value}
                            onChange={this._changeSettings('email_support')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

StoreDetails.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}


export default StoreDetails
