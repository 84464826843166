import React, {Component} from 'react'
import PropTypes from 'prop-types'
import OrderItemsUnfulfilled from "./OrderItemsUnfulfilled"
import OrderListFulfilled from "./OrderListFulfilled"
import {getOrder} from "../../../../services/api/OrdersServices"

class OrderFulfillItems extends Component{
    constructor(props) {
        super(props)
        this.state = {
            order: {},
            orderId: this.props.orderId
        }
        this.unfulfilled = React.createRef()
        this.fulfilled = React.createRef()
    }

    componentDidMount() {
        this._getOrder()
    }

    _getOrder = async () => {
        const {orderId} = this.state
        const {success, message, data} = await getOrder(orderId)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            order: data
        })
    }

    _reloadUnfulfilled = () => {
        this.unfulfilled.current ? this.unfulfilled.current._getItems() : this._getOrder()
    }

    render() {
        const {order} = this.state

        return (
            <div>
            {
            (order.fulfillment_status === 'unfulfilled' || order.fulfillment_status === 'partial') ?
                <OrderItemsUnfulfilled ref={this.unfulfilled} orderId={order._id}/> : null
            }
            {
            (order.fulfillment_status === 'fulfilled' || order.fulfillment_status === 'partial') ?
                <OrderListFulfilled ref={this.fulfilled} orderId={order._id}
                                    reloadUnfulfilled={this._reloadUnfulfilled}/> : null
            }
            </div>
        )
    }
}

OrderFulfillItems.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default OrderFulfillItems
