import React, {Component} from 'react'
import PropTypes from "prop-types"
import {Route, Switch, Redirect} from "react-router"
import HomeContainer from "../../home/components/HomeContainer"
import OrdersPageContainer from "../../orders/components/OrdersPageContainer"
import ImporterPageContainer from "../../importer/components/ImporterPageContainer"
import OrderContainer from "../../order/components/OrderContainer"
import OrderFulfillmentContainer from "../../order-fulfillment/components/OrderFulfillmentContainer"
import NavbarTop from "./NavbarTop"
import NavbarLeft from "./NavbarLeft"
import {getCurrentStore} from "../../../services/CurrentStoreService"
import getHistory from "../../../store/getHistory"
import SettingsPageContainer from "../../settings/components/SettingsPageContainer"
import SettingContainer from "../../setting/components/SettingContainer"
import classNames from 'classnames'
import ProductsPageContainer from "../../products/components/ProductsPageContainer"
import GoogleShoppingContainer from "../../google-shopping/components/GoogleShoppingContainer"
import ProductEditContainer from "../../product-edit/components/ProductEditContainer"
import AnalyticsPageContainer from "../../analytics/components/AnalyticsPageContainer"
import ImporterFormContainer from "../../importer-create/components/ImporterFormContainer"

class Dashboard extends Component {
    state = {
        isCollapsed: false
    }

    _handleClick() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }

    componentDidMount() {
        this._checkStore()
    }

    _checkStore = () => {
        const history = getHistory()

        const currentStore = getCurrentStore()
        if (!currentStore) {
            history.push('/stores')
        }
    }

    render() {
        const {store, basePath} = this.props
        const {isCollapsed} = this.state

        return (
            <div className={classNames("DashboardContainer", {isCollapsed})}>
                <NavbarLeft/>

                <div className="DashboardInner">
                    <NavbarTop store={store} onClick={() => this._handleClick()}/>

                    <div className="MainContent">
                        <div className="MainContentInner">
                            <Switch>
                                <Route path={`${basePath}`} exact component={HomeContainer}/>
                                <Route path={`${basePath}/orders`} exact component={OrdersPageContainer}/>
                                <Route path={`${basePath}/importer`} exact component={ImporterPageContainer}/>
                                <Route path={`${basePath}/importer/new`} exact component={ImporterFormContainer}/>
                                <Route path={`${basePath}/analytics`} exact component={AnalyticsPageContainer}/>
                                <Route path={`${basePath}/orders/:id`} exact component={OrderContainer}/>
                                <Route path={`${basePath}/orders/:id/fulfillment`} exact
                                       component={OrderFulfillmentContainer}/>
                                <Route path={`${basePath}/settings`} exact component={SettingsPageContainer}/>
                                <Route path={`${basePath}/settings/:setting`} exact component={SettingContainer}/>
                                <Route path={`${basePath}/products`} exact component={ProductsPageContainer}/>
                                <Route path={`${basePath}/google-shopping`} component={GoogleShoppingContainer}/>
                                <Route path={`${basePath}/products/:productId`} component={ProductEditContainer}/>

                                <Redirect from={`${basePath}/importer/product`} to={`${basePath}/importer/new`}/>
                                <Redirect to={`${basePath}`}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Dashboard.propTypes = {
    basePath: PropTypes.string.isRequired,
    store: PropTypes.object.isRequired,
}

export default Dashboard
