import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const _statusMap = {
    unfulfilled: {
        title: 'Unfulfilled'
    },
    fulfilled: {
        title: 'Fulfilled',
    },
    partial: {
        title: 'Partial'
    },
    restocked: {
        title: 'Restocked'
    }
}

class FulfillmentStatusColumn extends Component {
    render() {
        const {order, nameColumn} = this.props
        const {fulfillment_status} = order
        const statusText = _statusMap[fulfillment_status].title || 'Unknown'

        return (
            <td className={classNames("FulfillmentStatus", fulfillment_status)} key={nameColumn}>
                <div className="Text badge badge-secondary">
                    {statusText}
                </div>
            </td>
        )
    }
}

FulfillmentStatusColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired
}

export default FulfillmentStatusColumn
