import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListFulfillments} from "../../../../services/api/OrdersServices"
import OrderFulfilled from './OrderFulfilled'
import Modal from 'react-awesome-modal'

const listShippingCarrier = [
    'None', '4PX', 'APC', 'Amazon Logistics UK', 'Amazon Logistics US', 'Australia Post', 'Bluedart', 'Canada Post',
    'China Post', 'Correios', 'DHL Express', 'DHL eCommerce', 'DHL eCommerce Asia', 'DPD', 'DPD Local', 'DPD UK',
    'Delhivery', 'Eagle', 'FSC', 'FedEx', 'FedEx UK', 'GLS', 'Globegistics', 'Japan Post (EN)', 'Japan Post (JA)',
    'La Poste', 'New Zealand Post', 'Newgistics', 'PostNL', 'PostNord', 'Purolator', 'Royal Mail', 'SF Express',
    'Sagawa (EN)', 'Sagawa (JA)', 'Singapore Post', 'TNT', 'UPS', 'USPS', 'Whistl', 'Yamato (EN)', 'Yamato (JA)', 'Other'
]

class OrderListFulfilled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            messageItems: '',
            listFulfilled: [],
            loadingItems: true,
            isOpen: false,
            fulfilled: {}
        }
    }

    componentDidMount() {
        this._getListFulfilled()
    }

    _getListFulfilled = async () => {
        const {orderId} = this.state
        const {success, data, message} = await getListFulfillments(orderId)

        if (!success) {
            return this.setState({
                loadingItems: false,
                messageItems: message
            })
        }

        this.setState({
            loadingItems: false,
            listFulfilled: data
        })
    }

    _cancelFulfilled = (id) => {
        this.setState((state) => {
            return {
                listFulfilled: state.listFulfilled.filter((fulfilled) => {
                    return fulfilled._id !== id
                })
            }
        })
        this.props.reloadUnfulfilled()
    }

    _openModal = (fulfilledId) => {
        this.setState((state) => {
            return {
                isOpen: true,
                fulfilled: state.listFulfilled.filter((fulfilled) => {
                    return fulfilledId === fulfilled._id
                })[0]
            }
        })
    }

    _closeModal = () => {
        this.setState({
            isOpen: false
        })
    }

    _handleTrackingNumber = (e) => {
        const vl = e.target.value
        this.setState((state) => {
            return {
                fulfilled: {
                    ...state.fulfilled,
                    tracking_number: vl
                }
            }
        })
    }

    _handleTrackingUrl = (e) => {
        const vl = e.target.value
        this.setState((state) => {
            return {
                fulfilled: {
                    ...state.fulfilled,
                    tracking_url: vl
                }
            }
        })
    }

    _handleNotifyCustomer = () => {
        this.setState((state) => {
            return {
                fulfilled: {
                    ...state.fulfilled,
                    notify_customer: !state.fulfilled.notify_customer
                }
            }
        })
    }

    _editTracking = () => {
        console.log(this.state.fulfilled)
        this._closeModal()
    }

    _renderModal = () => {
        return (
            <Modal visible={this.state.isOpen} effect="fadeInUp"
                   onClickAway={() => this._closeModal()}>
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit tracking</h5>
                        <button type="button" className="close" onClick={() => this._closeModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Tracking number</label>
                            <input type="text" className="form-control"
                                   value={this.state.fulfilled.tracking_number}
                                   onChange={(e) => this._handleTrackingNumber(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Tracking url</label>
                            <input type="text" className="form-control"
                                   value={this.state.fulfilled.tracking_url}
                                   onChange={(e) => this._handleTrackingUrl(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Shipping carrier</label>
                            <select id="inputState" className="form-control" defaultValue="None">
                                {listShippingCarrier.map((ship, i) => {
                                    return <option key={i}>{ship}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input"
                                   checked={this.state.fulfilled.notify_customer}
                                   onChange={() => this._handleNotifyCustomer()}
                            />
                            <label className="form-check-label" htmlFor="exampleCheck1">Send shipment details to your
                                customer now</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-outline-dark"
                                onClick={() => this._closeModal()}>Cancel
                        </button>
                        <button type="button" className="btn btn-sm btn-primary"
                                onClick={() => this._editTracking()}>Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    render() {
        const {listFulfilled, orderId, isOpen} = this.state

        return (
            <div className="OrderListFulfilled">
                {
                    listFulfilled.map(fulfilled => {
                        return (
                            <OrderFulfilled
                                fulfilled={fulfilled}
                                orderId={orderId}
                                key={fulfilled._id}
                                cancelFulfilled={this._cancelFulfilled}
                                openModal={this._openModal}
                            />
                        )
                    })
                }
                {isOpen ? this._renderModal() : null}
            </div>
        )
    }
}

OrderListFulfilled.propsType = {
    orderId: PropTypes.string.isRequired,
    reloadUnfulfilled: PropTypes.func.isRequired
}

export default OrderListFulfilled
