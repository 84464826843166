import React, {Component} from 'react'
import ImporterPagination from './ImporterPagination'
import RowsFilter from './RowsFilter'
import StatusFilter from './StatusFilter'
import {fetchListJobs, removeProductViaJob, retryJob} from "../../../services/api/ImporterServices"
import TableImporterJobs from "./TableImporterJobs"

const REFRESH_TIME = 10000

class ImporterPage extends Component {
    state = {
        items: [],
        loading: false,
        error: '',
        params: {
            page: 1,
            limit: 20,
            status: ''
        },
        pages: 0,
        total: 0
    }

    _refresh = null

    componentDidMount() {
        this._fetchListProducts()
        this._setRefresh()
    }

    componentWillUnmount() {
        this._refresh && clearInterval(this._refresh)
    }

    _setRefresh = () => {
        this._refresh = setInterval(() => {
            this._fetchListProducts()
        }, REFRESH_TIME)
    }

    _fetchListProducts = async () => {
        const {params} = this.state

        this.setState({
            loading: true
        })

        try {
            const {success, data, message} = await fetchListJobs(params)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false
                })
            }

            const {docs, pages, total} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(docs) ? docs : [],
                loading: false,
                error: '',
                pages: pages ? pages : 0,
                total: total ? total : 0
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e
            })
        }
    }
    _handleRowChange = limit => {
        const {params} = this.state
        this.setState({params: {...params, limit, page: 1}}, () =>
            this._fetchListProducts()
        )
    }
    _handleStatusChange = status => {
        const {params} = this.state
        this.setState({params: {...params, status, page: 1}}, () =>
            this._fetchListProducts()
        )
    }

    _handleRemove = async row => {
        if (!row) {
            return false
        }

        const {product, _id: jobId} = row
        if (!product) return false

        const {success, message} = await removeProductViaJob(jobId)
        if (!success) {
            return window.alert(message)
        }

        this._fetchListProducts()
    }

    _handleRetry = async row => {
        if (!row) {
            return false
        }

        const {_id: jobId} = row

        const {success, message} = await retryJob(jobId)
        if (!success) {
            return window.alert(message)
        }

        await this._fetchListProducts()

        return true
    }

    _switchPage = page => {
        const {params} = this.state
        this.setState(
            {
                params: {
                    ...params,
                    page
                }
            },
            () => this._fetchListProducts()
        )
    }

    render() {
        const {params, items, pages, total} = this.state
        const {limit, page} = params

        return (
            <div className='ImporterPage HomePage'>
                <StatusFilter handleStatusChange={this._handleStatusChange}/>

                <div className="TableMeta">
                    <span className="TableLength">
                        Show <RowsFilter handleChange={this._handleRowChange}/> entries
                    </span>

                    <span className="TotalItems">{total} items</span>
                </div>

                <TableImporterJobs
                    onRemove={this._handleRemove}
                    onRetry={this._handleRetry}
                    items={items}
                    page={page}
                    limit={limit}
                />

                <div className="TableNav">
                    <ImporterPagination
                        page={page}
                        pages={pages}
                        onChange={this._switchPage}
                    />
                </div>

            </div>
        )
    }
}

export default ImporterPage
