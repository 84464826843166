import React, {Component, createRef} from 'react'
import {bulkImport} from "../../../services/api/ImporterServices"
import {Link} from "react-router-dom"

class BulkImporterForm extends Component {
    state = {
        file: null,
        error: '',
        loading: false,
        success: false,
    }

    _inputFile = createRef()

    _handleUpload = e => {
        e.preventDefault()

        this._upload()
    }

    _upload = async () => {

        const {file, loading} = this.state
        if (loading) return false

        const postData = new FormData()
        postData.append('file', file)

        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {success, message} = await bulkImport(postData)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            this.setState({
                loading: false,
                error: '',
                file: null,
                success: true
            })

            this._inputFile.current.value = ''
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e,
            })
        }
    }

    _onChangeFile = (e) => {
        this.setState({
            file: e.target.files[0],
            error: '',
            success: false,
        })
    }

    render() {
        const {error, loading, file, success} = this.state
        const canUpload = !loading && file

        return (
            <div className="BulkImporterForm">
                <div className="BulkImporterInner">
                    <div className="Header mt-2 mb-2">
                        <h2>Bulk import</h2>
                        <div className="Description">You can import multiple products from a CSV file.</div>
                    </div>

                    <div className="Main">
                        {
                            !!error &&
                            <div className="Error text-danger">
                                {error}
                            </div>
                        }
                        <div className="UploadForm mb-2">
                            <div className="form-group">
                                <input ref={this._inputFile} disabled={loading} onChange={this._onChangeFile}
                                       accept="text/csv" type="file"/>
                            </div>

                            <div className="upload">
                                <button disabled={!canUpload} onClick={this._handleUpload}
                                        className="btn btn-primary">Upload
                                </button>
                            </div>
                        </div>
                        {
                            !!success &&
                            <div className="Success">
                                You imported products successfully. Please <Link to="/a/importer">go back</Link> to see
                                products that you imported.
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkImporterForm
