import React, {Component} from 'react'
import OrderNotes from './OrderNotes'
import OrderHistories from './OrderHistories'
import OrderBuyerDetails from './OrderBuyerDetails'
import {getOrder} from '../../../services/api/OrdersServices'
import OrderPaymentPending from "./OrderPaymentPending"
import {Link} from "react-router-dom"
import OrderFulfillItems from "./OrderFulfillItems/OrderFulfillItems"
import OrderTags from "./OrderTags"

class OrderContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            err: '',
            id: this.props.match.params.id,
            order: {}
        }
    }

    componentDidMount() {
        this._getOrder()
    }

    _getOrder = async () => {
        const {id} = this.state
        const {success, message, data} = await getOrder(id)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            order: data
        })
    }

    render() {
        const {id, err, order} = this.state

        return (
            <div className="container-fluid OrderContainer">
                {!err ?
                    <div>
                        <p className="text-muted">
                            <Link to="/a/orders" className="text-muted">
                                <i className="fas fa-chevron-left"/> Orders</Link>
                        </p>
                        <h4 className="font-weight-bold">#{order.code}</h4>
                        <div className="row">
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <OrderFulfillItems orderId={id}/>
                                <OrderPaymentPending orderId={id}/>
                                <OrderNotes orderId={id}/>
                                <OrderHistories orderId={id}/>
                            </div>
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <OrderBuyerDetails orderId={id}/>
                                <OrderTags orderId={id}/>
                            </div>
                        </div>
                    </div> : <h3 className="text-center">{err}</h3>
                }
            </div>
        )
    }
}

export default OrderContainer
