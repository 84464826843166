import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"

class StoreItem extends Component {
    render() {
        const {store: {_id, name, domain, slug, status}} = this.props

        const Inner = status === 'active'
            ? (
                <Link to={`/stores/change-to/${slug}`}>
                    {name} - {domain}
                </Link>
            )
            : (
                <span>
                     {name} - {domain} - <span className="Status">pending</span>
                </span>
            )

        return (
            <div id={`store-${_id}`} className="StoreItem">
                {Inner}
            </div>
        )
    }
}

StoreItem.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreItem
