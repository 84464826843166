import React, {Component} from 'react'
import GoogleShopping from "./GoogleShopping"

class GoogleShoppingContainer extends Component {
    render() {
        return (
            <section className="GoogleShoppingContainer">
                <h1 className="PageTitle">Google shopping</h1>
                <div className="SectionInner">
                    <GoogleShopping/>
                </div>
            </section>
        )
    }
}

export default GoogleShoppingContainer
