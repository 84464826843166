import React, {Component} from "react"
import PropTypes from "prop-types"

import SettingInput from "../../setting/shared/SettingInput"

class StoreAppearance extends Component {
    _changeSettings = (key) => (value) => {
        this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return <div className="StoreAppearance">
            <div className="row">
                <div className="col-12">
                    <SettingInput
                        label="Logo URL"
                        itemId="logo_url"
                        disabled={loading.fetchSettings}
                        value={form['logo_url'].value || ''}
                        onChange={this._changeSettings('logo_url')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <SettingInput
                        label="Favicon URL"
                        itemId="favicon_url"
                        disabled={loading.fetchSettings}
                        value={form['favicon_url'].value || ''}
                        onChange={this._changeSettings('favicon_url')}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <SettingInput
                        label="Banner URL"
                        itemId="banner_url"
                        disabled={loading.fetchSettings}
                        value={form['banner_url'].value}
                        onChange={this._changeSettings('banner_url')}
                    />
                </div>
            </div>
        </div>
    }
}

StoreAppearance.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StoreAppearance
