import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({
    getBaseURL: (currentStore, isProduction = false) => {
        return isProduction ? `/api/stores/${currentStore}/google-shopping` : `https://pro.merch8.com/api/stores/${currentStore}/google-shopping`
    }
})

export const isAuthorizedGGS = () => {
    return api.makeAuthRequest({
        url: '/authorized',
        method: 'GET',
    })
}

export const revokeCredential = () => {
    return api.makeAuthRequest({
        url: '/revoke',
        method: 'POST',
    })
}

export const getAccountInformation = () => {
    return api.makeAuthRequest({
        url: '/account/info',
        method: 'GET',
    })
}

