import React, {Component} from 'react'
import PropTypes from 'prop-types'
import OrderNumberColumn from "./OrderTableColumn/OrderNumberColumn"
import ShippingAddressOrderColumn from './OrderTableColumn/ShippingAddressOrderColumn'
import TimeOrderColumn from './OrderTableColumn/TimeOrderColumn'
import TotalOrderColumn from './OrderTableColumn/TotalOrderColumn'
import OrderPaymentStatusColumn from './OrderTableColumn/OrderPaymentStatusColumn'
import NoteOrderColumn from './OrderTableColumn/NoteOrderColumn'
import FulfillmentStatusColumn from "./OrderTableColumn/FulfillmentStatusColumn"
import OrderRevenueColumn from "./OrderTableColumn/OrderRevenueColumn"
import getHistory from "../../../store/getHistory"
import classNames from 'classnames'
import OrderTagsColumn from "./OrderTableColumn/OrderTagsColumn"

const columns = [
    {
        name: 'number',
        title: 'Number',
        width: 1,
    },
    {
        name: 'time',
        title: 'Time',
        width: 2,
    },
    {
        name: 'customer',
        title: 'Customer',
        width: 3,
    },
    {
        name: 'tags',
        title: 'Tags',
        width: 1,
    },
    {
        name: 'payment_status',
        title: 'Payment status',
        width: 1,
    },
    {
        name: 'fulfillment_status',
        title: 'Fulfillment status',
        width: 1,
    },
    {
        name: 'revenue',
        title: 'Revenue',
        width: 1,
    },
]

class OrderTableRow extends Component {
    _handleClickOrder = e => {
        e.preventDefault()

        const {order: {_id}} = this.props
        const history = getHistory()
        history.push(`/a/orders/${_id}`)
    }

    render() {
        const {order} = this.props
        const {payment_status, fulfillment_status} = order
        const className = [`payment-${payment_status}`, `fulfillment-${fulfillment_status}`]

        return (
            <tr className={classNames("OrderTableRow", className)} onClick={this._handleClickOrder} key={order._id}>
                {columns.map((column) => {
                    switch (column.name) {
                        case 'number':
                            return <OrderNumberColumn nameColumn={column.name} orderId={order._id}
                                                      orderCode={order.code}
                                                      key={order.code}/>
                        case 'customer':
                            return <ShippingAddressOrderColumn nameColumn={column.name}
                                                               address={order.shipping_address}
                                                               key={order.shipping_address._id}/>
                        case 'time':
                            return <TimeOrderColumn nameColumn={column.name} order={order} key={order._id + 'time'}/>
                        case 'total':
                            return <TotalOrderColumn nameColumn={column.name} order={order} key={order._id + 'total'}/>
                        case 'payment_status':
                            return <OrderPaymentStatusColumn nameColumn={column.name} order={order}
                                                             key={order._id + 'status'}/>
                        case 'fulfillment_status':
                            return <FulfillmentStatusColumn nameColumn={column.name} order={order}
                                                            key={order._id + 'fulfillment'}/>
                        case 'revenue':
                            return <OrderRevenueColumn nameColumn={column.name} order={order}
                                                       key={order._id + 'revenue'}/>
                        case 'note':
                            return <NoteOrderColumn nameColumn={column.name} order={order} key={order._id + 'note'}/>

                        case 'tags':
                            return <OrderTagsColumn nameColumn={column.name} order={order} key={order._id + 'tags'}/>
                        default:
                            return null
                    }
                })}
            </tr>
        )
    }
}

OrderTableRow.propTypes = {
    order: PropTypes.object.isRequired
}

export default OrderTableRow
