import React, {Component} from 'react'
import PropTypes from 'prop-types'

const _statusMap = {
    paid: {
        title: 'Paid'
    },
    pending: {
        title: 'Pending',
    },
    refunded: {
        title: 'Refunded'
    }
}

class OrderPaymentStatusColumn extends Component {
    render() {
        const {order, nameColumn} = this.props
        const {payment_status} = order
        const statusText = _statusMap[payment_status] ? _statusMap[payment_status].title : 'Unknown'

        return (
            <td className="OrderPaymentStatusColumn" key={nameColumn}>
                <div className="Text badge badge-secondary">
                    {statusText}
                </div>
            </td>
        )
    }
}

OrderPaymentStatusColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired
}

export default OrderPaymentStatusColumn
