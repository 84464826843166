import React, {Component} from 'react'

class NotifyCustomer extends Component {
    state = {
        notify_customer: true
    }

    _handleNotifyCustomer = () => {
        this.setState((state) => {
            return {notify_customer: !state.notify_customer}
        })
    }

    render() {
        const {notify_customer} = this.state

        return (
            <div className="card-body">
                <p className="font-weight-bold text-dark">Notify customer shipment</p>
                <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input"
                           checked={notify_customer}
                           onChange={() => this._handleNotifyCustomer()}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">Send shipment details to your customer
                        now</label>
                </div>
            </div>
        )
    }
}

export default NotifyCustomer
