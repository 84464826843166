import React, {Component} from 'react'

const listShippingCarrier = [
    'None', '4PX', 'APC', 'Amazon Logistics UK', 'Amazon Logistics US', 'Australia Post', 'Bluedart', 'Canada Post',
    'China Post', 'Correios', 'DHL Express', 'DHL eCommerce', 'DHL eCommerce Asia', 'DPD', 'DPD Local', 'DPD UK',
    'Delhivery', 'Eagle', 'FSC', 'FedEx', 'FedEx UK', 'GLS', 'Globegistics', 'Japan Post (EN)', 'Japan Post (JA)',
    'La Poste', 'New Zealand Post', 'Newgistics', 'PostNL', 'PostNord', 'Purolator', 'Royal Mail', 'SF Express',
    'Sagawa (EN)', 'Sagawa (JA)', 'Singapore Post', 'TNT', 'UPS', 'USPS', 'Whistl', 'Yamato (EN)', 'Yamato (JA)', 'Other'
]

class TrackingInformation extends Component {
    state = {
        tracking_number: '',
        tracking_url: '',
        tracking_company: 'None'
    }

    _handleTrackingNumber = (e) => {
        this.setState({
            tracking_number: e.target.value
        })
        if (e.target.value && this.state.tracking_company === 'None') {
            this.setState({
                tracking_company: 'Other'
            })
        }
    }

    _handleTrackingUrl = (e) => {
        this.setState({
            tracking_url: e.target.value
        })
    }

    _handleTrackingCompany = (e) => {
        if (e.target.value === 'None') {
            return this.setState({
                tracking_company: e.target.value,
                tracking_number: '',
                tracking_url: ''
            })
        }
        this.setState({
            tracking_company: e.target.value
        })

    }

    render() {
        const {tracking_number, tracking_url, tracking_company} = this.state

        return (
            <div className="card-body">
                <p className="font-weight-bold text-dark">Tracking infomation (Optional)</p>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>Tracking number</label>
                            <input type="text" className="form-control"
                                   value={tracking_number}
                                   onChange={(e) => this._handleTrackingNumber(e)}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Shipping carrier</label>
                            <select id="inputState" className="form-control"
                                    value={tracking_company}
                                    onChange={(e) => this._handleTrackingCompany(e)}
                            >
                                {listShippingCarrier.map((ship, i) => {
                                    return <option key={i}>{ship}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                {
                    tracking_company === 'Other' ?
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Tracking url</label>
                                    <input type="text" className="form-control"
                                           value={tracking_url}
                                           onChange={(e) => this._handleTrackingUrl(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>
        )
    }
}

export default TrackingInformation
