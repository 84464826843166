import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ShippingAddressOrderColumn extends Component {
    render() {
        const {nameColumn, address} = this.props
        const {email, full_name, country} = Object.assign({}, address)

        return (
            <td key={nameColumn}>
                <div className="OrderAddress">
                    <div className="FullName">{full_name}</div>
                    <div className="Email">{email}</div>
                    <div className="Country">{country}</div>
                </div>
            </td>
        )
    }
}

ShippingAddressOrderColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired
}

export default ShippingAddressOrderColumn
