import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({suffix: '/statistics'})

export const fetchOrderStatisticsOverview = (data = {}) => {
    return api.makeAuthRequest({
        url: '/orders/overview',
        method: 'POST',
        data
    })
}

export const fetchEventStatistics = (data = {}) => {
    return api.makeAuthRequest({
        url: '/orders/events',
        method: 'POST',
        data
    })
}
