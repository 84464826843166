import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from "react-country-flag"
import {getOrderBuyerDetail} from "../../../services/api/OrdersServices"
import EditBuyerDetail from "../../modal/components/EditBuyerDetail"

class OrderBuyerDetails extends Component {
    constructor(props) {
        super(props)
        this.editBuyerDetail = React.createRef()
        this.state = {
            orderId: this.props.orderId,
            message: '',
            buyerDetail: {
                customer: {},
                shipping_address: {
                    country_code: ''
                }
            }
        }
    }

    componentDidMount() {
        this._getBuyerDetails()
    }

    _getBuyerDetails = async () => {
        const {orderId} = this.state
        const {success, data, message} = await getOrderBuyerDetail(orderId)

        if (!success) {
            return this.setState({
                message: message
            })
        }

        this.setState({
            buyerDetail: data
        })
    }

    _editBuyerDetail = (buyerDetail) => {
        this.setState({
            buyerDetail: {
                shipping_address: buyerDetail
            }
        })
    }

    _renderModalEdit = () => {
        this.setState({
            renderModalEdit: true
        }, () => {
            this.editBuyerDetail.current._openModal()
        })
    }

    render() {
        const {shipping_address} = this.state.buyerDetail
        const {full_name, address, postal_code, city, state, country_code, country, phone, email} = shipping_address
        const {message, renderModalEdit, orderId} = this.state

        return (
            <div className="OrderBuyerDetails">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        Buyer Details
                        <i className="fas fa-edit float-right cursor-pointer"
                            onClick={() => { this._renderModalEdit()}}
                        />
                    </div>
                    {!message ?
                        <div className="card-body text-dark">
                            { full_name && <div className="font-weight-bold">{full_name}</div> }
                            { address && <div>{address}</div> }
                            <div>{city}{state && ` - ${state}`}{postal_code && ` - ${postal_code}`}</div>
                            { country_code && <ReactCountryFlag code={country_code} svg/> }
                            { country && <span> {country}</span> }
                            {phone &&
                            <div>
                                <i className="fas fa-phone" />
                                <span>&#32;{phone || 'null'}</span>
                            </div>
                            }
                            {email &&
                            <div>
                                <i className="fa fa-envelope" aria-hidden="true" />
                                <span>&#32; {email}</span>
                            </div>
                            }
                        </div> : <div>{message}</div>}
                </div>
                {
                    renderModalEdit ?
                        <EditBuyerDetail
                            ref={this.editBuyerDetail}
                            orderId={orderId}
                            shipping_address={shipping_address}
                            editBuyerDetail={this._editBuyerDetail}
                        /> : null
                }
            </div>
        )
    }
}

OrderBuyerDetails.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default OrderBuyerDetails
