import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import {getListUnfulfilledItems} from "../../../../services/api/OrdersServices"
import OrderItem from './OrderItem'

class OrderItemsUnfulfilled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            messageItems: '',
            items: [],
            quantityItems: 0,
            loadingItems: true,
        }
    }

    componentDidMount() {
        this._getItems()
    }

    _getItems = async () => {
        const {orderId} = this.state
        const {success, data, message} = await getListUnfulfilledItems(orderId)

        if (!success) {
            return this.setState({
                loadingItems: false,
                messageItems: message
            })
        }

        this.setState({
            loadingItems: false,
            items: data
        }, () => this._getQuantityItems())
    }

    _getQuantityItems = () => {
        const {items} = this.state
        let total = 0

        items.forEach(item => {
            total += item.quantity
        })

        this.setState({
            quantityItems: total
        })
    }

    _renderItems = (loadingItems, messageItems, items) => {
        return (
            !loadingItems ?
                (!messageItems ?
                        (items.map((item, i) => {
                            return <OrderItem item={item} key={item._id}/>
                        })) : <div>{messageItems}</div>
                ) : ''
        )
    }

    render() {
        const {items, loadingItems, messageItems, orderId, quantityItems} = this.state

        return (
            <div className="OrderItemsUnfulfilled">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        Unfulfilled ({quantityItems})
                    </div>
                    <div className="card-body">
                        {this._renderItems(loadingItems, messageItems, items)}
                    </div>
                    <div className="card-body text-right">
                        <Link to={`/a/orders/${orderId}/fulfillment`}>
                            <button className="btn btn-sm btn-primary">Mark as fulfilled</button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

OrderItemsUnfulfilled.propsType = {
    orderId: PropTypes.string.isRequired
}

export default OrderItemsUnfulfilled
