import React, {Component} from "react"
import {getSettingsByCategory, saveSettingsByCategory} from "../../../services/api/SettingServices"
import SettingSectionDetails from "../../setting/shared/SettingSectionDetails"
import Paypal from "./Paypal"
import Stripe from "./Stripe"
import classNames from "classnames";

class SettingPaymentContainer extends Component {
    state = {
        loading: {
            fetchSettings: false,
            spin: false,
        },
        form: {
            'paypal_client_key': '',
            'paypal_secret_key': '',
            'paypal_env': '',
            'paypal_enabled': '',
            'stripe_public_key': '',
            'stripe_secret_key': '',
            'stripe_enabled': '',
        }
    }

    componentDidMount() {
        this._getSettings()
    }

    _getSettings = async () => {
        this.setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                fetchSettings: true,
            }
        }))

        const {success, data} = await getSettingsByCategory('payment')
        if (success) {
            const form = data.reduce((accumulator, setting) => ({
                ...accumulator,
                [setting.key]: setting.value,
            }), {})
            this.setState(state => ({
                ...state,
                loading: {
                    ...state.loading,
                    fetchSettings: false,
                },
                form: {
                    ...state.form,
                    ...form,
                }
            }))
        }
    }

    _onChangeSetting = (key, value) => {
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [key]: value,
            }
        }))
    }

    _clickSaveButton = () => {
        this._saveSettings()
    }

    _saveSettings = async () => {
        const {form} = this.state
        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: true,
            }
        }))
        const {success} = await saveSettingsByCategory('payment', Object.keys(form).map((key) => ({
            key,
            value: form[key]
        })))

        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: false,
                saveSuccess: success,
            }
        }))
    }

    render() {
        const {loading, form} = this.state
        const {_onChangeSetting} = this
        const spin = loading.spin

        return (
            <div className="SettingContainer">
                <div className="Section">
                    <SettingSectionDetails title="Paypal"/>
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <Paypal
                                    loading={loading}
                                        form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Section">
                    <SettingSectionDetails title="Stripe"/>
                    <div className="SectionSetting">
                        <div className="card Card">
                            <div className="card-body shadow-sm">
                                <Stripe
                                    loading={loading}
                                    form={form}
                                    onChange={_onChangeSetting}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ButtonSave">
                    <button
                        className="btn btn-primary"
                        onClick={this._clickSaveButton}
                        disabled={loading.spin}
                    >
                        <span className="Label">Save</span>
                        <span className={classNames("Spin", {spin})}/>
                    </button>
                </div>
            </div>
        )
    }
}

export default SettingPaymentContainer
