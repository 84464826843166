import React, {Component} from "react"
import PropTypes from "prop-types"

class SettingCheckbox extends Component {
    _onClick = (e) => {
        const {onChange} = this.props
        onChange && onChange(e.target.checked)
    }

    render() {
        const {label, checked, itemId, disabled} = this.props
        const inputProps = {
            checked: checked,
            id: itemId,
            disabled,
        }

        return (
            <div className="SettingCheckbox">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        onChange={this._onClick}
                        {...inputProps}
                    />
                    {
                        !!label &&
                        <label className="custom-control-label" htmlFor={itemId}>
                            {label}
                        </label>
                    }
                </div>
            </div>
        )
    }
}

SettingCheckbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    smallText: PropTypes.string,
    placeholder: PropTypes.string,
    itemId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
}

export default SettingCheckbox
