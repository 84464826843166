const _map = {
    all: 'All',
    pending: 'Pending',
    retrying: 'Retrying',
    processing: 'Processing',
    completed: 'Completed',
    deleted: 'Deleted',
    error: 'Error',
    'not-importable': 'Cannot import'
}

export default (status) => {
    return _map[status] || 'Unknown'
}
