import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router'
import HomeContainer from './home/components/HomeContainer'
import LoginPageContainer from "./login/components/LoginPageContainer"
import DashboardContainer from "./dashboard/components/DashboardContainer"
import OauthCallbackContainer from "./oauth-callback/components/OauthCallbackContainer"
import StoresPageContainer from "./stores/components/StoresPageContainer"
import ChangeToStoreContainer from "./change-store/components/ChangeToStoreContainer"
import DocTitle from "./shared/DocTitle"
import LogoutPageContainer from "./logout/components/LogoutPageContainer"

class App extends Component {
    render() {
        return (
            <div className="App">
                <DocTitle title="Merchize - Sellers"/>
                <Switch>
                    <Route path="/" exact component={HomeContainer}/>
                    <Route path="/stores" exact component={StoresPageContainer}/>
                    <Route path="/stores/change-to/:storeId" exact component={ChangeToStoreContainer}/>
                    <Route path="/a" component={DashboardContainer}/>
                    <Route path="/login" component={LoginPageContainer}/>
                    <Route path="/logout" component={LogoutPageContainer}/>
                    <Route path="/oauth-callback" component={OauthCallbackContainer}/>
                    <Redirect to="/"/>
                </Switch>
            </div>
        )
    }
}

export default App
