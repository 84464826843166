import React, {Component} from "react"

import {searchOrders} from "../../../services/api/OrdersServices"

import OrdersPagination from './OrdersPagination'
import OrdersFilter from './OrdersFilter'
import OrderTableRow from "./OrderTableRow"

const columns = [
    {
        name: 'number',
        title: 'Number',
        width: 1,
    },
    {
        name: 'time',
        title: 'Time',
        width: 2,
    },
    {
        name: 'customer',
        title: 'Customer',
        width: 3,
    },
    {
        name: 'tags',
        title: 'Tags',
        width: 1,
    },
    {
        name: 'payment_status',
        title: 'Payment status',
        width: 1,
    },
    {
        name: 'fulfillment_status',
        title: 'Fulfillment status',
        width: 1,
    },
    {
        name: 'revenue',
        title: 'Revenue',
        width: 1,
    },
]

class OrdersTable extends Component {
    state = {
        page: 1,
        limit: 20,
        orders: {
            err: '',
            list: [],
            pages: 1,
            total: 0,
        },
        filter: {
            payment_status: 'paid',
        },
        loading: false,
    }

    componentDidMount() {
        this._getOrders()
    }

    _getOrders = async () => {
        let {page, limit, orders, filter} = this.state
        this.setState({
            loading: true,
        })

        try {
            const {success, data, message} = await searchOrders({
                page: page || 1,
                limit: limit || 10,
                payment_status: filter.payment_status || '',
            })

            if (!success) return this.setState({
                loading: false,
                orders: {
                    ...orders,
                    err: message,
                }
            })

            return this.setState({
                orders: {
                    ...orders,
                    err: null,
                    list: data.orders,
                    pages: data.pages,
                    total: data.total,
                },
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                orders: {
                    ...orders,
                    err: e.message || e,
                    loading: false,
                },
            })
        }
    }

    _switchPage = (page) => {
        this.setState({
            page
        }, () => this._getOrders())
    }

    _changePaymentStatus = (status) => {
        this.setState({
            filter: {
                payment_status: status,
            }
        }, () => this._getOrders())
    }

    _renderTableBody = (orders) => {
        if (!orders.list) return null
        if (!orders.list.length) return null
        const {list} = orders

        return list.map((order, index) => {
                return (
                    <OrderTableRow order={order} key={index}/>
                )
            }
        )
    }

    render() {
        const {orders, page, limit, loading} = this.state
        const {err, total} = orders

        return (
            <div className={"OrdersTable"}>
                <OrdersFilter changeFilter={this._changePaymentStatus}/>
                {(!loading) ? (err) ?
                    <p className="text-warning">
                        {err}
                    </p> : <div className="OrderTableInner">
                        <span className="TotalItems">{total} order(s)</span>
                        <div className="table-responsive">
                            <table className="table orders-table Table">
                                <thead className="thead-light">
                                <tr>
                                    {columns.map((column) => {
                                        return (
                                            <th key={column.name}
                                                scope="col"
                                                className={`col-${column.width} column-${column.width}`}>
                                                {column.title}
                                            </th>
                                        )
                                    })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this._renderTableBody(orders)}
                                </tbody>
                            </table>
                        </div>

                        <div className="TableNav">
                            <OrdersPagination
                                limit={limit}
                                page={page}
                                pages={orders.pages}
                                onChange={this._switchPage}
                            />
                        </div>
                    </div> : <p>Loading...</p>}
            </div>
        )
    }
}

export default OrdersTable
