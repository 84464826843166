import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {cancelFulfilled, getListFulfilledItems} from "../../../../services/api/OrdersServices"
import OrderItem from "./OrderItem"
import EditTrackingInfo from "../../../modal/components/EditTrackingInfo"

class OrderFulfilled extends Component {
    constructor(props) {
        super(props)
        this.editTrackingInfo = React.createRef()
        this.state = {
            fulfilled: props.fulfilled,
            fulfilledItems: [],
            quantityItems: 0,
            orderId: props.orderId,
            err: '',
            openModal: false
        }
    }

    componentDidMount() {
        this._getItems()
    }

    _getItems = async () => {
        const {orderId, fulfilled} = this.state
        const {success, data, message} = await getListFulfilledItems(orderId, fulfilled._id)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            fulfilledItems: data
        }, () => this._getQuantityItems())
    }

    _getQuantityItems = () => {
        const {fulfilledItems} = this.state

        fulfilledItems.forEach(item => {
            this.setState((state) => {
                return {
                    quantityItems: state.quantityItems + item.quantity
                }
            })
        })
    }

    _cancelFulfilled = async () => {
        const {orderId, fulfilled} = this.state
        const {success, message} = await cancelFulfilled(orderId, fulfilled._id)

        if (!success) {
            return alert(message)
        }
        this.props.cancelFulfilled(fulfilled._id)
    }

    _editTracking = () => {
        this.setState({
            openModal: true
        }, () => {
            this.editTrackingInfo.current._openModal()
        })
    }

    _refreshTracking = (trackingInfo) => {
        const {tracking_number, tracking_url, tracking_company, notify_customer} = trackingInfo
        this.setState((state) => {
            return {
                fulfilled: {
                    ...state.fulfilled,
                    tracking_number: tracking_number,
                    tracking_url: tracking_url,
                    tracking_company: tracking_company,
                    notify_customer: notify_customer
                }
            }
        })
    }

    _renderItems = (items) => {
        return (
            items.map((item, i) => {
                return <OrderItem item={item} key={item._id}/>
            })
        )
    }

    render() {
        const {fulfilled, err, fulfilledItems, quantityItems, openModal, orderId} = this.state
        const {_id, tracking_number, tracking_url, tracking_company, notify_customer} = fulfilled

        return (
            err ? <div>{err}</div> :
                <div className="OrderFulfilled card">
                    <div className="card-header">
                        <span className="font-weight-bold">Fulfilled ({quantityItems})</span>
                        <span> # {fulfilled.name}</span>
                    </div>
                    <div className="card-body">
                        {fulfilled.tracking_company ?
                            <div className="text-muted">{fulfilled.tracking_company} tracking</div> : null
                        }
                        {fulfilled.tracking_number ?
                            <div className="text-dark">{fulfilled.tracking_number}</div> : null
                        }
                        {this._renderItems(fulfilledItems)}
                    </div>
                    <div className="card-body text-right">
                        <button className="btn btn-sm btn-outline-dark mr-1"
                                onClick={() => this._cancelFulfilled()}>Cancel
                        </button>
                        {fulfilled.tracking_company ?
                            <button className="btn btn-sm btn-outline-dark" onClick={() => this._editTracking()}>Edit
                                tracking
                            </button>
                            :
                            <button className="btn btn-sm btn-primary" onClick={() => this._editTracking()}>Add
                                tracking
                            </button>
                        }
                    </div>
                    {
                        openModal?
                            <EditTrackingInfo
                                ref={this.editTrackingInfo}
                                orderId={orderId}
                                fulfilledId={_id}
                                tracking_number={tracking_number || ''}
                                tracking_company={tracking_company || ''}
                                tracking_url={tracking_url || ''}
                                notify_customer={notify_customer}
                                refreshTracking={this._refreshTracking}
                            />
                            : null
                    }
                </div>
        )
    }
}

OrderFulfilled.propTypes = {
    fulfilled: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    cancelFulfilled: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired
}

export default OrderFulfilled
