import React, {Component} from 'react'
import PropTypes from 'prop-types'

const statusSelection = [
    {
        title: 'All',
        value: '',
        select: true
    },
    {
        title: 'Pending',
        value: 'pending'
    },
    {
        title: 'Retrying',
        value: 'retrying'
    },
    {
        title: 'Processing',
        value: 'processing'
    },
    {
        title: 'Completed',
        value: 'completed'
    },
    {
        title: 'Deleted',
        value: 'deleted'
    },
    {
        title: 'Error',
        value: 'error'
    },
    {
        title: 'Cannot import',
        value: 'not-importable'
    }
]

class StatusFilter extends Component {
    state = {
        value: ''
    }

    _handleFilter = value => e => {
        statusSelection.forEach((status, index) => {
            statusSelection[index].select = value === status.value
        })
        this.props.handleStatusChange(value)
    }

    render() {
        return (
            <div className='StatusFilter LabelFilter'>
                <label>Status:</label>
                <ul className='nav nav-pills FilterItems' role='tablist'>
                    {statusSelection.map(status => {
                        return (
                            <li
                                className={status.select ? 'active' : ''}
                                onClick={this._handleFilter(status.value)}
                                key={status.value}>
                                <span
                                    className='nav-link'
                                    data-toggle='pill'
                                    role='tab'
                                    aria-controls='pills-home'
                                    aria-selected='true'>
                                    {status.title}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

StatusFilter.propTypes = {
    handleStatusChange: PropTypes.func.isRequired
}

export default StatusFilter
