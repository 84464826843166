import React, {Component} from "react"
import PropTypes from "prop-types"
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

import {deleteProduct} from "../../../services/api/ProductServices"
import getHistory from "../../../store/getHistory"

class DeleteWarning extends Component {
    state = {
        loading: false,
    }

    _toggle = () => {
        this.props.onToggle()
    }

    _clickDelete = () => {
        const {product} = this.props

        this._submitDelete(product._id)
    }

    _submitDelete = async (_id) => {
        this.setState({
            loading: true,
        })

        const {success} = await deleteProduct(_id)
        if (success) console.log(success)

        this.setState({
            loading: false,
        })
        this.props.onToggle()
        const history = getHistory()
        history.push('/a/products')
    }

    render() {
        const {isShow, product} = this.props
        const {loading} = this.state

        return <div className="DeleteWarning">
            <Modal isOpen={isShow} toggle={this._toggle}>
                <ModalHeader toggle={this._toggle}>Delete product</ModalHeader>
                <ModalBody>
                    <div className="Text">
                            <span className="text-muted">
                                Are you sure you want to delete the product
                            </span>
                        <span>&nbsp;</span>
                        <span className="font-weight-bold">
                                {product.title}
                            </span>
                        <span className="text-muted">
                                ? This action cannot be reversed.
                            </span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-outline-primary"
                        onClick={this._toggle}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger"
                        disabled={loading}
                        onClick={this._clickDelete}
                    >
                        <span><i className="fas fa-exclamation-triangle"/></span>
                        <span className="ml-2">
                        Delete product
                        </span>
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    }
}

DeleteWarning.propTypes = {
    product: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
}

export default DeleteWarning
