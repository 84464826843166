import React, {Component} from "react"
import PropTypes from "prop-types"
import TagEdit from "./organization-edit/TagEdit"
import CollectionsEdit from "./organization-edit/CollectionsEdit"

class OrganizationEdit extends Component {
    _changeTag = (tags) => {
        this.props.onChange('tags', tags)
    }

    _changeCollections = (collections) => {
        this.props.onChange('collections', collections)
    }

    render() {
        const {product} = this.props

        return <div className="OrganizationEdit">
            <div className="SectionInner EditSection">
                <span className="Title">Organization</span>

                <div className="Block">
                    <CollectionsEdit
                        collections={product.collections || []}
                        onChange={this._changeCollections}
                    />
                </div>

                <div className="Block">
                    <TagEdit
                        onChange={this._changeTag}
                        tags={product.tags || []}
                    />
                </div>
            </div>
        </div>
    }
}

OrganizationEdit.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default OrganizationEdit
