import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CollectionsColumn extends Component {
    _getCollections = () => {
        const {item} = this.props
        const {collections} = item

        return Array.isArray(collections) ? collections : []
    }

    render() {
        const collections = this._getCollections()

        return (
            <td className="CollectionsColumn">
                <ul className="ListCollections">
                    {
                        collections.map(collection => {
                            const {_id, name} = collection

                            return (
                                <li key={_id} className="badge badge-secondary">{name}</li>
                            )
                        })
                    }
                </ul>
            </td>
        )
    }
}

CollectionsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CollectionsColumn
