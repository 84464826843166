import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from "moment-timezone"
import WithStoreSettings from "../../../shared/WithStoreSettings"
import humanizeTime from "../../../../helpers/time/humanizeTime"

class TimeOrderColumn extends Component {
    _getFormattedTime = () => {
        const {order, store} = this.props
        const {created, updated, paid_at} = order
        const validatedTime = updated || paid_at || created

        const timezone = store.getTimezone()
        const momentTime = moment(validatedTime).utcOffset(timezone)

        return humanizeTime(momentTime)
    }

    render() {
        const {nameColumn} = this.props
        const time = this._getFormattedTime()

        return (
            <td key={nameColumn}>
                <div>{time}</div>
            </td>
        )
    }
}

TimeOrderColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {store => {
                return (
                    <TimeOrderColumn {...props} store={store}/>
                )
            }}
        </WithStoreSettings>
    )
}