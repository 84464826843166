import React, {Component} from "react"
import SettingInput from "../../setting/shared/SettingInput"
import PropTypes from "prop-types"
import SettingSelect from "../../setting/shared/SettingSelect"
import {countryList, usStateList} from "../../../statics/country-list"

class StoreAddress extends Component {
    _changeSettings = (key) => (value) => {
        if (key === 'country') {
            if (value === 'United States') return this.props.onChange(key, value)
            this.props.onChange(key, value)
            this.props.onChange('state', '')
        } else {
            this.props.onChange(key, value)
        }
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="StoreAddress">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            itemId="legal_name"
                            label="Legal name of business"
                            disabled={loading.fetchSettings}
                            value={form['legal_name'].value || ''}
                            onChange={this._changeSettings('legal_name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Phone"
                            itemId="phone"
                            disabled={loading.fetchSettings}
                            value={form['phone'].value || ''}
                            onChange={this._changeSettings('phone')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Address"
                            itemId="address"
                            disabled={loading.fetchSettings}
                            value={form['address'].value || ''}
                            onChange={this._changeSettings('address')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SettingInput
                            itemId="city"
                            label="City"
                            disabled={loading.fetchSettings}
                            value={form['city'].value || ''}
                            onChange={this._changeSettings('city')}
                        />
                    </div>
                    <div className="col-6">
                        <SettingInput
                            itemId="zip_code"
                            label="Postal / ZIP code"
                            disabled={loading.fetchSettings}
                            value={form['zip_code'].value || ''}
                            onChange={this._changeSettings('zip_code')}
                        />
                    </div>
                </div>

                {(form['country'].value !== 'United States') ?
                    <div className="row">
                        <div className="col-12">
                            <SettingSelect
                                itemId="country"
                                label="Country"
                                disabled={loading.fetchSettings}
                                value={form['country'].value || ''}
                                options={countryList.map(country => ({
                                    title: country.name,
                                    value: country.name,
                                }))}
                                onChange={this._changeSettings('country')}
                            />
                        </div>
                    </div> : <div className="row">
                        <div className="col-6">
                            <SettingSelect
                                itemId="country"
                                label="Country"
                                disabled={loading.fetchSettings}
                                value={form['country'].value || ''}
                                options={countryList.map(country => ({
                                    title: country.name,
                                    value: country.name,
                                }))}
                                onChange={this._changeSettings('country')}
                            />
                        </div>
                        <div className="col-6">
                            <SettingSelect
                                itemId="state"
                                label="State"
                                disabled={loading.fetchSettings}
                                value={form['state'].value || ''}
                                options={usStateList}
                                onChange={this._changeSettings('state')}
                            />
                        </div>
                    </div>}
            </div>
        )
    }
}

StoreAddress.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StoreAddress
