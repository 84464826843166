import {getLocalData, setLocalData} from "./StorageServices"
import PubSub from 'pubsub.js'
import {fetchStoreSettingsBySlug} from "./api/SellerServices"

const contextArgument = ['currentStore']
const instance = PubSub.newInstance({
    context: contextArgument,
    async: true,
})

const _getCurrentStoreFormStorage = () => {
    const storeId = getLocalData('currentStore', '')

    return (storeId + '').trim()
}

const _setCurrentStore = storeId => {
    const storeValidated = (storeId + '').trim()

    _store.storeId = storeValidated
    setLocalData('currentStore', storeValidated)
}

const _getStoreSettingsFormStorage = () => {
    const settings = getLocalData('currentStoreSettings', [])

    return Array.isArray(settings) ? settings : []
}

const _setStoreSettings = (settings = []) => {
    const validatedSettings = Array.isArray(settings) ? settings : []

    _store.settings = validatedSettings
    setLocalData('currentStoreSettings', validatedSettings)
}

const _broadcast = () => {
    const {storeId} = _store

    instance.publish('STORE_CHANGED', [{storeId}])

}

const _store = {
    storeId: _getCurrentStoreFormStorage(),
    settings: _getStoreSettingsFormStorage()
}

export const subscribeChangeStore = (subscriber) => {
    return instance.subscribe('STORE_CHANGED', (data) => {
        subscriber(data)
    })
}

export const unsubscribeChangeStore = token => {
    instance.unsubscribe(token)
}

export const getCurrentStore = () => {
    return _store.storeId
}

export const getCurrentStoreSettings = () => {
    return _store.settings || []
}

export const getStoreSetting = (key, defaultValue = null) => {
    const {settings} = _store
    const settingsValidated = Array.isArray(settings) ? settings : []
    const setting = settingsValidated.find(setting => setting.key === key)

    if (!setting) return defaultValue

    const {value} = Object.assign({}, setting)

    return value || defaultValue
}

export const refreshStoreSettings = async () => {
    const {storeId} = _store

    if (!storeId) return false

    try {
        const {success, data, message} = await fetchStoreSettingsBySlug(storeId)

        if (!success) {
            return console.error(message)
        }

        _setStoreSettings(data)
        _broadcast()
    } catch (e) {
        console.log(e)
    }

    return true
}

export const setCurrentStore = (storeId, settings = []) => {
    const storeIdValidated = storeId ? (storeId + '').trim() : ''

    _setCurrentStore(storeIdValidated)
    _setStoreSettings(settings)
    _broadcast()

    return _store.storeId
}

