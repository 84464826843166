import React, {Component} from 'react'
import makeAnimated from 'react-select/lib/animated'
import {saveCollection} from '../../../services/api/ImporterAPIServices'
import PropTypes from 'prop-types'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable'
import {searchCollections} from "../../../services/api/ImporterServices"

class CollectionsSelector extends Component {
    state = {
        selectedCollections: [],
        selectedOptions: [],
        options: [],
        err: '',
        params: {q: ''},
        input: '',
        message: ''
    }

    componentDidMount() {
        this._getCollections()
    }

    componentDidUpdate(prevProps) {
        const {isImportSuccess, toggleSuccess} = this.props
        if (isImportSuccess !== prevProps.isImportSuccess && isImportSuccess) {
            this.setState(
                {
                    selectedOptions: []
                },
                () => toggleSuccess()
            )
        }
    }

    _getCollections = async () => {
        return new Promise(async resolve => {
            const {params: {q: name}} = this.state

            try {
                const {success, data, message} = await searchCollections({name})
                if (!success) {
                    return this.setState({
                        err: message
                    })
                }

                const {docs} = Object.assign({}, data)
                const collections = Array.isArray(docs) ? docs : []

                this.setState(
                    () => ({
                        options: collections.map(collection => {
                            return {
                                id: collection._id,
                                value: collection.slug,
                                label: collection.name
                            }
                        })
                    }),
                    () => resolve(this.state.options)
                )
            } catch (e) {
                this.setState({
                    err: e.message || e
                })
            }
        })
    }

    _handleChange = options => {
        this.setState(
            {
                selectedCollections: options.map(option => {
                    return option.id
                }),
                selectedOptions: options
            },
            () => {
                this.props.handleSelectCollection(
                    this.state.selectedCollections
                )
            }
        )
    }

    _handleInputChange = input => {
        return this.setState(
            {
                params: {q: input},
                input: input
            },
            () => {
                this._getCollections()
            }
        )
    }

    _getNoOptionsMessage = () => {
        return 'There are no collections!'
    }

    _getLoadingMessage = () => {
        return 'Loading collections...'
    }

    _formatCreateLabel = input => {
        return `Create "${input}" collection`
    }

    _onCreateOption = async input => {
        const slug = input
            .toLocaleLowerCase()
            .trim()
            .replace(/\s/g, '-')
        this.setState({
            message: "Creating collection..."
        })
        if (!input.trim().length) return
        try {
            const {data, success, message} = await saveCollection(input)
            await this._getCollections()
            if (!success) {
                return this.setState({
                    err: message,
                    message: ''
                })
            }
            this.setState(
                {
                    selectedCollections: [
                        ...this.state.selectedCollections,
                        data._id
                    ],
                    selectedOptions: [
                        ...this.state.selectedOptions,
                        {value: slug, label: input}
                    ],
                    message: ''
                },
                () =>
                    this.props.handleSelectCollection(
                        this.state.selectedCollections
                    )
            )
        } catch (e) {
            this.setState({
                err: e.message || e
            })
        }
    }

    render() {
        const {selectedOptions, options, message, input, err} = this.state

        return (
            <div className="CollectionsSelector col">
                <div className='form-group'>
                    <AsyncCreatableSelect
                        components={makeAnimated()}
                        onChange={this._handleChange}
                        onInputChange={this._handleInputChange}
                        inputValue={input}
                        defaultOptions={options}
                        onFocus={this._onFocus}
                        noOptionsMessage={this._getNoOptionsMessage}
                        loadingMessage={this._getLoadingMessage}
                        createOptionPosition='first'
                        allowCreateWhileLoading
                        formatCreateLabel={this._formatCreateLabel}
                        onCreateOption={this._onCreateOption}
                        isMulti
                        loadOptions={this._getCollections}
                        value={selectedOptions}
                        placeholder='Search collections'
                    />
                    {err && (
                        <p className='text-danger'>{err}</p>
                    )}
                    {message &&
                        <p className='text-primary'>{message}</p>
                    }
                </div>
            </div>
        )
    }
}

CollectionsSelector.propTypes = {
    handleSelectCollection: PropTypes.func.isRequired,
    isImportSuccess: PropTypes.bool.isRequired
}

export default CollectionsSelector
