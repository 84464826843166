import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class StatisticBox extends Component {
    render() {
        const {statistics, className} = this.props

        return (
            <div className={classNames("card StatisticItem", className)}>
                <div className="card-body text-left">
                    {statistics.map(statistic => {
                        const {key, title, value} = statistic

                        return (
                            <p className="card-text" key={key}>
                                <span className="Title">{title || key}</span>
                                <span> : </span>
                                <span className="Value">{value || 0}</span>
                            </p>
                        )
                    })}
                </div>
            </div>
        )
    }
}

StatisticBox.defaultProps = {
    className: ''
}

StatisticBox.propTypes = {
    statistics: PropTypes.array.isRequired,
    className: PropTypes.string,
}

export default StatisticBox
