import React, {Component} from 'react'
import PropTypes from 'prop-types'
import OrderStatisticsContainer from "./order/OrderStatisticsContainer"

class AnalyticsPage extends Component {
    render() {
        return (
            <div className="AnalyticsPage">
                <div className="AnalyticsInner SectionInner">
                    <OrderStatisticsContainer {...this.props}/>
                </div>
            </div>
        )
    }
}

AnalyticsPage.propTypes = {
    store: PropTypes.object.isRequired,
}

export default AnalyticsPage
