import Axios from "axios"
import getEnv from "../../helpers/common/getEnv"
import {getAccessToken} from "../AuthServices"

const _makeRequest = createRequest => async args => {
    const _headers = args.headers ? args.headers : {}

    const defaultHeaders = {}

    args = {
        ...args,
        headers: {
            ...defaultHeaders,
            ..._headers
        },
    }

    try {
        const {data} = await createRequest(args)

        return data
    } catch (e) {
        throw e
    }
}

const _makeAuthRequest = createRequest => async (args) => {
    const requestHeaders = args.headers ? args.headers : {}
    const accessToken = getAccessToken()

    let headers = {
        'Authorization': `Bearer ${accessToken}`,
        'cache-control': 'no-cache',
    }

    args = {
        ...args,
        headers: {
            ...headers,
            ...requestHeaders
        }
    }

    return await _makeRequest(createRequest)(args)
}

export default (options = {}) => {
    const baseUrlValidated = options.baseUrl || getEnv('baseAPIUrl')
    const instance = Axios.create({
        baseURL: baseUrlValidated,
        timeout: 30000,
    })


    const _changeBaseURL = (baseURL) => {
        instance.defaults.baseURL = baseURL
    }

    return {
        changeBaseURL: _changeBaseURL,
        makeRequest: _makeRequest(instance),
        makeAuthRequest: _makeAuthRequest(instance),
    }
}