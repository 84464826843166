import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderItem extends Component {
    _getItemTotal = (item) => {
        return (item.quantity * item.price).toFixed(2)
    }

    render() {
        const {item} = this.props

        return (
            <div className="Item card-body row" key={item._id}>
                <div className="col-2">
                    <img src={item.image} alt="err" className="img-fluid"/>
                </div>
                <div className="col-6 card-text text-muted m-auto">
                    <div>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.product.title}
                    </a>
                    </div>
                    <div>Item: #{item._id}</div>
                    <div>{item.variant.title || ''}</div>
                    {
                        item.product.type ? <span
                            className="badge badge-primary"> {item.product.type.type} - {item.product.type.vendor}</span> : ''
                    }
                </div>
                <div className="col-2 card-text text-muted text-right m-auto">
                    {item.quantity} x ${item.price}
                </div>
                <div className="col-2 card-text text-muted text-right m-auto">
                    ${this._getItemTotal(item)}
                </div>
            </div>
        )
    }
}

OrderItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default OrderItem
