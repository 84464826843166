import React, {Component} from "react"
import PropTypes from "prop-types"

class SettingSectionDetails extends Component {
    render() {
        const {title, description} = this.props

        return (
            <div className="SectionDetails">
                <p className="font-bold">{title}</p>
                <small className="text-muted">{description}</small>
            </div>
        )
    }
}

SettingSectionDetails.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
}

export default SettingSectionDetails
