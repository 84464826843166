import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderTableRow from "./RenderTableRow"

class TableBodyImporter extends Component {
    render() {
        const {items, onRemove, onRetry, page, limit} = this.props

        return (
            <tbody className="TableBodyImporter">
            {
                items.map((item, index) => {
                    const order = limit * (page - 1) + index + 1

                    return (
                        <RenderTableRow
                            key={item._id}
                            item={item}
                            order={order}
                            onRemove={onRemove}
                            onRetry={onRetry}
                        />
                    )
                })
            }
            </tbody>
        )
    }
}

TableBodyImporter.propTypes = {
    items: PropTypes.array.isRequired,
}

export default TableBodyImporter
