import React, {Component} from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import {makeProductsActive, makeProductsDeactive} from "../../../services/api/ProductServices"

class StatusEdit extends Component {
    state = {
        statusChange: {
            open: false,
        }
    }

    _toggleStatusChange = (action) => (e) => {
        this.setState(state => ({
            statusChange: {
                ...state.statusChange,
                open: (action) ? (action === 'open') : !state.statusChange.open
            }
        }))
    }

    _changeProductStatus = (status) => (e) => {
        if (status) {
            this._submitChangeStatus(this.props.product._id, status)
        }
    }

    _submitChangeStatus = async (id, status) => {
        const work = (status === 'Active') ? makeProductsActive : makeProductsDeactive

        const {success} = await work([id])
        if (success) this.props.onChange('is_active', (status === 'Active'))
    }

    _mapStatus = () => {
        const {product} = this.props

        if (product.is_active) return 'Active'
        return 'Deactive'
    }

    render() {
        const {statusChange} = this.state
        const {product} = this.props

        return <div className="StatusEdit">
            <div className="ChangeStatus">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={product.url || '/'}
                    className="Link"
                >
                    <span
                        className="btn StatusButton"
                    >
                    <span className="Title">
                            View
                    </span>
                        <span>
                    <i className="fas fa-external-link-alt"/>
                    </span>
                    </span>
                </a>
                <span
                    className={classnames("btn StatusButton", {
                        "Focus": statusChange.open
                    })}
                    onClick={this._toggleStatusChange()}
                >
                    <span className="Title">Status: {this._mapStatus()}</span>
                    <span><i className="fas fa-caret-down"/></span>
                    <div className={classnames("StatusWrapper", {
                        "Hidden": !statusChange.open
                    })}>
                        <div className="card Card">
                            <div className="card-body CardBody shadow-sm">
                                <ul className="StatusList">
                                    <li className="Status"
                                        onClick={this._changeProductStatus('Active')}
                                    >Make item available
                                    </li>
                                    <li className="Status"
                                        onClick={this._changeProductStatus('Deactive')}
                                    >Make item unavailable
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </span>
                {(statusChange.open) ? <div className="Background" onClick={this._toggleStatusChange('close')}/> : null}
            </div>
        </div>
    }
}

StatusEdit.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StatusEdit
