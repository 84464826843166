import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({suffix: '/product'})

export const getAllOrders = (page) => {
    return api.makeAuthRequest({
        url: '/products',
        method: 'GET',
        params: {
            page
        }
    })
}

export const changeProductStatus = (_id, status) => {
    return api.makeAuthRequest({
        url: '/changeProductStatus',
        method: 'POST',
        data: {
            _id,
            status,
        }
    })
}

export const makeProductsActive = (ids) => {
    return api.makeAuthRequest({
        url: '/products/activate',
        method: 'POST',
        data: {
            ids,
        }
    })
}

export const makeProductsDeactive = (ids) => {
    return api.makeAuthRequest({
        url: '/products/deactivate',
        method: 'POST',
        data: {
            ids,
        }
    })
}

export const getProduct = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}`,
        method: 'GET',
    })
}

export const saveProductGeneralEdit = (_id, details) => {
    return api.makeAuthRequest({
        url: `/products/${_id}`,
        method: 'POST',
        data: {
            ...details,
        }
    })
}

export const searchCollectionsByName = (name) => {
    return api.makeAuthRequest({
        url: '/collections/search',
        method: 'GET',
        params: {
            name
        }
    })
}

export const saveCollectionByName = (name) => {
    return api.makeAuthRequest({
        url: '/collections/create',
        method: 'POST',
        data: {
            name
        }
    })
}

export const deleteProduct = (id) => {
    return api.makeAuthRequest({
        url: `/products/${id}/delete`,
        method: 'POST',
    })
}
