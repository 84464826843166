import React, {Component} from "react"
import SettingInput from "../../setting/shared/SettingInput"
import PropTypes from "prop-types"

class GoogleTracking extends Component {
    _changeSettings = (key) => value => {
        return this.props.onChange(key, value)
    }

    render() {
        const {loading, form} = this.props

        return (
            <div className="GoogleTracking">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Google Tag Manager"
                            itemId="gtm"
                            disabled={loading.fetchSettings}
                            value={form['gtm'] || ''}
                            onChange={this._changeSettings('gtm')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Google Analytics"
                            itemId="google_analytics"
                            disabled={loading.fetchSettings}
                            value={form['google_analytics'] || ''}
                            onChange={this._changeSettings('google_analytics')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Google Conversion Tracking"
                            itemId="google_conversion_tracking"
                            disabled={loading.fetchSettings}
                            value={form['google_ads'] || ''}
                            onChange={this._changeSettings('google_ads')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

GoogleTracking.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default GoogleTracking
