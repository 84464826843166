import React, {Component} from "react"
import SettingSelect from "../../setting/shared/SettingSelect"
import PropTypes from "prop-types"
import {timeZoneList} from "../../../statics/timezone-list"

class StandardFormat extends Component {
    _changeSettings = (key) => (value) => {
        this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="StandardFormat">
                <div className="row">
                    <div className="col-12">
                        <SettingSelect
                            label="Timezone"
                            itemId="timezone"
                            options={timeZoneList}
                            value={form['timezone'].value || ''}
                            disabled={loading.fetchSettings}
                            onChange={this._changeSettings('timezone')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

StandardFormat.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StandardFormat
