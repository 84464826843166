import React, {Component} from 'react'
import PropTypes from 'prop-types'

const _urlBeforeSource = 'https://href.li/'

class DestinationColumn extends Component {
    render() {
        const {item} = this.props
        const {title, url} = Object.assign({}, item.product)
        const href = url ? `${_urlBeforeSource}${url}` : ''

        return (
            <td className='DestinationColumn Col'>
                <div className="ColumnInner">
                    {
                        !!href &&
                        <a href={href}
                           rel='noopener noreferrer'
                           target='_blank'>
                            {title}
                        </a>
                    }
                </div>
            </td>
        )
    }
}

DestinationColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default DestinationColumn
