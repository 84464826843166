import React, {Component} from "react"
import PropTypes from "prop-types"

class Input extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value,
            })
        }
    }

    _changeInput = (e) => {
        const {onChange} = this.props
        this.setState({
            value: e.target.value,
        })
        if (onChange) this.props.onChange(e.target.value)
    }

    render() {
        const {label, textarea} = this.props
        const {value} = this.state

        const inputProps = {
            value,
        }

        return <div className="ProductEditInput">
            <div className="form-group">
                {label ? <label>{label}</label> : null}
                {textarea ? <textarea
                    className="form-control"
                    {...inputProps}
                    onChange={this._changeInput}
                /> : <input
                    className="form-control"
                    {...inputProps}
                    onChange={this._changeInput}
                />}
            </div>
        </div>
    }
}

Input.propsTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.object,
    textarea: PropTypes.bool,
}

export default Input
