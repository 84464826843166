import React, {Component} from 'react'
import CollectionsSelector from './CollectionsSelector'
import {Link} from "react-router-dom"
import {newImporterJob} from "../../../services/api/ImporterServices"

class ImporterForm extends Component {
    state = {
        form: {
            url: '',
            title: '',
            collections: [],
            tags: ''
        },
        loading: false,
        error: '',
        result: {},
        isImportSuccess: false
    }

    _handleChangeInput = field => e => {
        const {value} = e.target

        this.setState(({form}) => ({
            form: {
                ...form,
                [field]: value
            }
        }))
    }

    _handleSubmit = e => {
        e.preventDefault()
        this._submit()
    }

    _handleSelectCollection = collections =>
        this.setState({
            form: {
                ...this.state.form,
                collections
            }
        })

    _submit = async () => {
        const {form, loading} = this.state

        if (loading) return false

        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {success, data, message} = await newImporterJob({...form})

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState(({form}) => ({
                loading: false,
                result: {
                    ...data
                },
                form: {
                    ...form,
                    url: '',
                    title: '',
                    collections: [],
                    tags: ''
                },
                isImportSuccess: true
            }))
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e
            })
        }
    }

    _toggleSuccess = () => {
        this.setState({
            isImportSuccess: !this.state.isImportSuccess
        })
    }

    render() {
        const {form, loading, error} = this.state
        const {url, title, collections, tags} = form

        return (
            <div className='ImportForm mb-4'>
                <div className="BackWrapper">
                    <Link className="nav-link BackButton" to="/a/importer">
                        <i className="fas fa-chevron-left"/>
                        <span>Importer</span>
                    </Link>
                </div>
                <h2>Import product</h2>
                <p className="SubTitle">You can use Merchize’s Import feature to quickly migrate your products from
                    other platforms to your store.<br/>
                    Supported platforms: TeeChip, GearLaunch, Moteefe, Viralstyle, Shopify, WooCommerce (beta).</p>

                {!!error && <div className='text-danger mb-2'>{error}</div>}

                <form className='form'>
                    <div className='row'>
                        <div className='form-group col'>
                            <input
                                type='text'
                                value={url}
                                onChange={this._handleChangeInput('url')}
                                placeholder='Enter product url'
                                className='form-control'
                            />
                        </div>
                        <div className='form-group col'>
                            <input
                                type='text'
                                value={title}
                                onChange={this._handleChangeInput('title')}
                                placeholder='Enter product title'
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="row">
                        <CollectionsSelector
                            collections={collections}
                            handleSelectCollection={this._handleSelectCollection}
                            isImportSuccess={this.state.isImportSuccess}
                            toggleSuccess={this._toggleSuccess}
                        />

                        <div className="form-group col">
                            <input
                                type='text'
                                value={tags}
                                onChange={this._handleChangeInput('tags')}
                                placeholder='Enter product tags. Example: tag1, tag2'
                                className='form-control'
                            />
                        </div>
                    </div>

                    <div className='form-group'>
                        <button
                            disabled={loading}
                            onClick={this._handleSubmit}
                            className='btn btn-primary'>
                            Import
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default ImporterForm
