import React, {Component} from 'react'
import AnalyticsPage from "./AnalyticsPage"
import WithStoreSettings from "../../shared/WithStoreSettings"

class AnalyticsPageContainer extends Component {
    render() {
        return (
            <div className="AnalyticsPageContainer">
                <AnalyticsPage {...this.props}/>
            </div>
        )
    }
}

export default function (props) {
    return (
        <WithStoreSettings>
            {store => {
                return (
                    <AnalyticsPageContainer store={store} {...props}/>
                )
            }}
        </WithStoreSettings>
    )
}
