import React, {Component} from 'react'
import PropTypes from 'prop-types'

class NoteOrderColumn extends Component {
    render() {
        const {order, nameColumn} = this.props
        const {meta} = Object.assign({}, order)
        const {fulfillment_note} = Object.assign({}, meta)

        return (
            <td key={nameColumn}>
                <div className="Text">
                    {fulfillment_note || ''}
                </div>
            </td>
        )
    }
}

NoteOrderColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    order: PropTypes.object.isRequired
}

export default NoteOrderColumn
