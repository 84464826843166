import React, {Component} from 'react'
import Modal from 'react-awesome-modal'
import PropTypes from 'prop-types'
import countryList from 'react-select-country-list'
import {editAddress} from "../../../services/api/OrdersServices"

let listCountry = countryList().getData()
listCountry.push({label: 'None'})

class EditBuyerDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            orderId: this.props.orderId,
            shipping_address: this.props.shipping_address
        }
    }

    _openModal = () => {
        this.setState({
            isOpen: true
        })
    }

    _closeModal = () => {
        this.setState({
            isOpen: false
        })
    }

    _getCountryCode = (label) => {
        return listCountry.filter(ct => {
            return ct.label === label
        })[0].value
    }

    _handleShippingAddress = (prop, e) => {
        const {value} = e.target
        if (prop === "country") {
            return this.setState((state) => {
                return {
                    shipping_address: {
                        ...state.shipping_address,
                        [prop]: value,
                        country_code: this._getCountryCode(value)
                    }
                }
            }, () => {
                console.log(this.state)
            })
        }
        this.setState({
            shipping_address: {
                ...this.state.shipping_address,
                [prop]: value
            }
        })
    }

    _editBuyerDetail = async () => {
        const {orderId, shipping_address} = this.state
        const {success, data, message} = await editAddress(orderId, shipping_address._id, shipping_address)

        if (!success) {
            return alert(message)
        }
        this.props.editBuyerDetail(data)
        this.setState({
            isOpen: false
        })
    }

    render() {
        const {full_name, phone, email, address, city, country, postal_code} = this.state.shipping_address

        return (
            <div className="EditBuyerDetail">
                <Modal visible={this.state.isOpen} effect="fadeInUp"
                       onClickAway={() => this._closeModal()}
                >
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit shipping info</h5>
                            <button type="button" className="close" onClick={() => this._closeModal()}
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Full name</label>
                                <input type="text" className="form-control"
                                       value={full_name || ''}
                                       onChange={(e) => this._handleShippingAddress('full_name', e)}
                                />
                            </div>
                            <div className="form-group row">
                                <div className="col-6">
                                    <label>Email</label>
                                    <input type="text" className="form-control"
                                           value={email || ''}
                                           onChange={(e) => this._handleShippingAddress('email', e)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label>Phone</label>
                                    <input type="text" className="form-control"
                                           value={phone || ''}
                                           onChange={(e) => this._handleShippingAddress('phone', e)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" className="form-control"
                                       value={address || ''}
                                       onChange={(e) => this._handleShippingAddress('address', e)}
                                />
                            </div>
                            <div className="form-group row">
                                <div className="col-6">
                                    <label>City</label>
                                    <input type="text" className="form-control"
                                           value={city || ''}
                                           onChange={(e) => this._handleShippingAddress('city', e)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label>Country</label>
                                    <select id="inputState" className="form-control" value={country || 'None'}
                                            onChange={(e) => this._handleShippingAddress('country', e)}
                                    >
                                        {listCountry.map((ship, i) => {
                                            return <option key={i} value={ship.label}>{ship.label}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-6">
                                    <label>Postal code</label>
                                    <input type="text" className="form-control"
                                           value={postal_code || ''}
                                           onChange={(e) => this._handleShippingAddress('postal_code', e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-outline-dark"
                                    onClick={() => this._closeModal()}>Cancel
                            </button>
                            <button type="button" className="btn btn-sm btn-primary"
                                    onClick={() => this._editBuyerDetail()}>Save
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

EditBuyerDetail.propTypes = {
    orderId: PropTypes.string.isRequired,
    shipping_address: PropTypes.object.isRequired,
    editBuyerDetail: PropTypes.func.isRequired
}

export default EditBuyerDetail
