import React, {Component} from "react"
import PropTypes from "prop-types"
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"
import {Link} from "react-router-dom"

import ProductAction from "./ProductAction"
import {productProperties} from "../../../statics/product-properties"
import {makeProductsActive, makeProductsDeactive} from "../../../services/api/ProductServices"

class ProductsTable extends Component {
    state = {
        actionOpen: [],
        selectedItems: {},
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.products) !== JSON.stringify(this.props.products)) {
            this.setState({
                actionOpen: this.props.products.map(item => false),
                selectedItems: this.props.products
                    .map(item => item._id)
                    .reduce((accumulator, current) => ({
                        ...accumulator,
                        [current]: false,
                    }), {}),
            })
        }
    }

    _toggleActions = (index) => () => {
        const {actionOpen} = this.state
        actionOpen[index] = !actionOpen[index]
        this.setState({
            actionOpen
        })
    }

    _selectItems = (item) => (e) => {
        const value = e.target.checked

        if (item === 'all') {
            const {products} = this.props
            this.setState(state => ({
                selectedItems: products
                    .map(item => item._id)
                    .reduce((accumulator, current) => ({
                        ...accumulator,
                        [current]: value,
                    }), {}),
            }))
        } else {
            this.setState(state => ({
                selectedItems: {
                    ...state.selectedItems,
                    [item]: value,
                }
            }))
        }
    }

    _changeProductStatus = (status) => {
        this._submitChangeStatus(status)
    }

    _submitChangeStatus = async (status) => {
        const {selectedItems} = this.state
        const {products} = this.props

        const changedItems = Object.keys(selectedItems)
            .filter(key => selectedItems[key])

        const request = (status === 'Active') ? makeProductsActive(changedItems) : makeProductsDeactive(changedItems)
        const {success, data} = await request

        if (success) {
            const updatedData = data.filter(item => item.updated)
            if (updatedData.length) {
                const updatedProducts = products.map(product => {
                    const updatedItem = updatedData.find(item => item._id === product._id)
                    
                    if (updatedItem) return {
                        ...product,
                        ...updatedItem,
                    }
                    return product
                })

                this.props.changeProducts(updatedProducts)
            }
        }
    }

    _renderCell = (product, property, index) => {
        const {actionOpen, selectedItems} = this.state

        switch (property.type) {
            case 'dropdown':
                if (!property.actions.length) return property.name
                return <ButtonDropdown
                    isOpen={actionOpen[index]}
                    toggle={this._toggleActions(index)}
                >
                    <DropdownToggle caret color="primary">
                        {property.title}
                    </DropdownToggle>
                    <DropdownMenu>
                        {property.actions.map(action => <DropdownItem key={action.name}>
                            {action.title}
                        </DropdownItem>)}
                    </DropdownMenu>
                </ButtonDropdown>
            case 'image':
                return <img
                    src={product[property.name]}
                    alt={product['title']}
                    className="ThumbnailImage"
                />
            case 'badges':
                return (Array.isArray(product[property.name])) ? product[property.name].map((badge, idx) => <span
                    key={idx}
                    className="badge badge-primary mr-1">{badge}</span>) : null
            case 'select':
                return <div className="pretty p-default p-curve" id="pretty-scale-test">
                    <input
                        type="checkbox"
                        checked={selectedItems[product._id] || false}
                        value={selectedItems[product._id] || false}
                        onChange={this._selectItems(product._id)}
                    />
                    <div className="state p-primary">
                        <label></label>
                    </div>
                </div>
            case 'list':
                if (!product[property.name] || !product[property.name].length) return <span className="text-muted font-italic">No collections</span>

                return product[property.name].map((item, id) => <div
                    key={id}
                    className="ListItem"
                >
                    <div className="badge badge-primary">
                        {item.name}
                    </div>
                </div>
            )
            default:
                if (property.name === 'title') return <Link
                    to={`/a/products/${product._id}`}
                >
                    {product[property.name]}
                </Link>
                return product[property.name]
        }
    }

    render() {
        const {products} = this.props
        const {selectedItems} = this.state

        return <div className="ProductsTable">
            <table className="table table-striped">

                <thead className="thead-light">
                <tr>
                    {Object.keys(selectedItems).find(key => selectedItems[key]) ? <th className="SelectBar">
                        <div className="pretty p-default p-curve" id="pretty-scale-test">
                            <input
                                type="checkbox"
                                onChange={this._selectItems('all')}
                                checked={Object.keys(selectedItems).every(key => selectedItems[key])}
                            />
                            <div className="state p-primary">
                                <label></label>
                            </div>
                        </div>
                        <div className="Menu">
                            <ProductAction
                                selectedItems={selectedItems}
                                onSelectOption={this._changeProductStatus}
                            />
                        </div>
                    </th> : productProperties.map((column, index) => <th
                        scope="col"
                        key={index}
                        style={{
                            width: (column.width) ? `${column.width}%` : '10%',
                        }}
                    >
                        {(column.type === 'select') ?
                            <div className="pretty p-default p-curve" id="pretty-scale-test">
                                <input
                                    type="checkbox"
                                    onChange={this._selectItems('all')}
                                    checked={Object.keys(selectedItems).every(key => selectedItems[key])}
                                />
                                <div className="state p-primary">
                                    <label></label>
                                </div>
                            </div> : column.title}
                    </th>)}
                </tr>
                </thead>

                {(!products || !products.length) ? null : <tbody>
                {products.map((product, index) => <tr key={product._id}>
                    {productProperties.map((column) => <td
                        key={column.name}
                        style={{
                            width: (column.width) ? `${column.width}%` : '10%',
                        }}
                    >
                        {this._renderCell(product, column, index)}
                    </td>)}
                </tr>)}
                </tbody>}
            </table>
        </div>
    }

}

ProductsTable.propTypes = {
    products: PropTypes.array.isRequired,
    changeProducts: PropTypes.func,
}

export default ProductsTable
