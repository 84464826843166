import React, {Component} from 'react'
import {getListPendingStores} from "../../../services/api/SellerServices"
import StoreItem from "./StoreItem"
import PubSub from '../PubSub'

class ListPendingStores extends Component {
    state = {
        stores: [],
        loading: false,
        error: ''
    }

    _subscribeNewStore = null

    componentDidMount() {
        this._handleNewStore()
        this._fetchListStores()
    }

    componentWillUnmount() {
        this._subscribeNewStore && PubSub.unsubscribe(this._subscribeNewStore)
    }

    _handleNewStore = () => {
        this._subscribeNewStore = PubSub.subscribe('STORE_CREATE_NEW_ONE', (newStore) => {
            this._fetchListStores()
        })
    }

    _fetchListStores = async () => {
        try {
            this.setState({
                loading: true,
                error: ''
            })

            const {success, data, message} = await getListPendingStores()
            if (!success) {
                return this.setState({
                    error: message,
                    loading: false
                })
            }

            this.setState({
                loading: false,
                error: '',
                stores: data
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message || e,
            })
        }
    }

    render() {
        const {stores} = this.state

        return (
            <div className="ListStores ListPendingStores">
                {stores.map(store => {
                    return (
                        <StoreItem key={store._id} store={store}/>
                    )
                })}
            </div>
        )
    }
}

export default ListPendingStores
