import React, {Component} from 'react'
import PropTypes from 'prop-types'

class RowsFilter extends Component {
    state = {
        value: 20
    }

    _handleChange = event => {
        this.setState(
            {
                value: event.target.value
            },
            () => this.props.handleChange(this.state.value)
        )
    }

    render() {
        return (
            <select value={this.state.value} onChange={this._handleChange}>
                <option value='10'>10 rows</option>
                <option value='20'>20 rows</option>
                <option value='50'>50 rows</option>
                <option value='100'>100 rows</option>
            </select>
        )
    }
}

RowsFilter.propTypes = {
    handleChange: PropTypes.func.isRequired
}

export default RowsFilter
