import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

class ImporterPagination extends Component {
    _handlePageClick = data => {
        const {selected} = data

        typeof this.props.onChange === 'function' &&
        this.props.onChange(selected + 1)
    }

    render() {
        const {pages, page} = this.props

        if (pages <= 1) return null

        return (
            <div className='ImporterPagination Pagination'>
                <ReactPaginate
                    pageCount={pages}
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this._handlePageClick}
                    containerClassName='pagination'
                    pageClassName='page-item'
                    previousClassName='Previous page-item'
                    nextClassName='Next page-item'
                    pageLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    subContainerClassName='pages pagination'
                    activeClassName='active'
                />
            </div>
        )
    }
}

ImporterPagination.defaultProps = {
    limit: 10
}

ImporterPagination.propTypes = {
    limit: PropTypes.number,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onChange: PropTypes.func
}

export default ImporterPagination
