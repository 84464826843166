import React, {Component} from "react"
import SettingSelect from "../../setting/shared/SettingSelect"
import {currency} from "../../../statics/currency"

class StoreCurrency extends Component {
    _changeSettings = (key) => (value) => {
        this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="StoreCurrency">
                <div className="SectionHeader">
                    <label htmlFor="currency">Store currency</label>
                </div>
                <SettingSelect
                    itemId="currency"
                    options={currency}
                    value={form['currency'].value}
                    disabled={loading.fetchSettings}
                    onChange={this._changeSettings('currency')}
                />
            </div>
        )
    }
}

export default StoreCurrency
