export const productProperties = [{
    title: '',
    isNotText: true,
    name: 'select',
    type: 'select',
    width: 1,
}, {
    title: '',
    isNotText: true,
    name: 'image',
    width: 5,
    type: 'image',
}, {
    title: 'Name',
    name: 'title',
    width: 20
}, {
    title: 'Collections',
    name: 'collections',
    type: 'list',
}, {
    title: 'Tags',
    name: 'tags',
    type: 'badges',

}, {
    title: 'Status',
    name: 'status',
}]
