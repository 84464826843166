import React, {Component} from 'react'
import StoresPage from "./StoresPage"
import EnsureLoggedIn from "../../shared/EnsureLoggedIn"
import DocTitle from "../../shared/DocTitle"

class StoresPageContainer extends Component {
    render() {
        return (
            <EnsureLoggedIn>
                <DocTitle title="Stores"/>
                <StoresPage/>
            </EnsureLoggedIn>
        )
    }
}

export default StoresPageContainer
