import React, {Component} from 'react'
import GeneralEdit from "./GeneralEdit"
import OrganizationEdit from "./OrganizationEdit"
import {getProduct, saveProductGeneralEdit} from "../../../services/api/ProductServices"
import DeleteWarning from "./DeleteWarning"
import StatusEdit from "./StatusEdit"

class ProductEditContainer extends Component {
    state = {
        product: {},
        updatedProduct: {},
        loading: {
            submitEdit: false,
        },
        isDeleteWarningOpen: false,
    }

    componentDidMount() {
        this._getProduct()
    }

    _getProduct = async () => {
        const {productId} = this.props.match.params
        if (!productId) return

        const {success, data} = await getProduct(productId)

        if (success) {
            this.setState({
                product: {...data},
                updatedProduct: {...data},
            })
        }
    }

    _changeProduct = (property, value) => this.setState(state => ({
        ...state,
        updatedProduct: {
            ...state.updatedProduct,
            [property]: value,
        }
    }))

    _changeStatus = (property, value) => {
        this.setState(state => ({
            product: {
                ...state.product,
                [property]: value,
            },
            updatedProduct: {
                ...state.updatedProduct,
                [property]: value,
            }
        }))
    }

    _discardChange = () => this.setState(state => ({
        updatedProduct: state.product,
    }))

    _saveEdit = () => {
        this._submitEdit()
    }

    _submitEdit = async () => {
        this.setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                submitEdit: true,
            }
        }))

        const {_id, ...details} = this.state.updatedProduct

        const {success} = await saveProductGeneralEdit(_id, {
            ...details,
            collections: details.collections
                .filter(collection => collection && collection._id)
                .map(collection => collection._id)
        })

        if (success) {
            this.setState(state => ({
                ...state,
                loading: {
                    ...state.loading,
                    submitEdit: false,
                },
                product: {...state.updatedProduct},
            }))
        } else {
            this.setState(state => ({
                loading: {
                    ...state.loading,
                    submitEdit: false,
                }
            }))
        }
    }

    _clickDeleteButton = () => {
        this._toggleDeleteWarning()
    }

    _toggleDeleteWarning = () => {
        this.setState({
            isDeleteWarningOpen: !this.state.isDeleteWarningOpen,
        })
    }

    render() {
        const {updatedProduct, product, loading, isDeleteWarningOpen} = this.state
        const isUpdated = JSON.stringify(updatedProduct) !== JSON.stringify(product)

        return <div className="ProductEditContainer">

            <div className="Header">
                <h1 className="PageTitle">{product.title || 'Product'}</h1>
                <StatusEdit
                    product={updatedProduct}
                    onChange={this._changeStatus}
                />
            </div>

            <div className="row">
                <div className="col-8">
                    <GeneralEdit
                        product={updatedProduct}
                        onChange={this._changeProduct}
                    />
                </div>

                <div className="col-4">
                    <OrganizationEdit
                        product={updatedProduct}
                        onChange={this._changeProduct}
                    />
                </div>
            </div>

            <div className="BottomButtons">
                <button
                    className="btn btn-danger ButtonLeft ButtonWithIcon"
                    onClick={this._clickDeleteButton}
                >
                    <span><i className="far fa-trash-alt"/></span>
                    <span>Delete Product</span>
                </button>
                <button
                    className="btn btn-primary ButtonRight ButtonWithIcon"
                    onClick={this._saveEdit}
                    disabled={!isUpdated || loading.submitEdit}
                >
                    <span>
                        <i className="far fa-save"/>
                    </span>
                    <span>Save</span>
                </button>
                {(isUpdated) ? <button
                    className="btn btn-primary ButtonRight mr-2"
                    onClick={this._discardChange}
                >
                    Discard changes
                </button> : null}
            </div>

            <DeleteWarning product={product} onToggle={this._toggleDeleteWarning} isShow={isDeleteWarningOpen}/>

        </div>
    }
}

export default ProductEditContainer
