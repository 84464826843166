import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductActions extends Component {
    state = {
        loadingRetry: false
    }

    _handleRemove = e => {
        e.preventDefault()

        const r = window.confirm('Are you sure to remove this product?')
        if (!r) return false

        this.props.onRemove(this.props.item)
    }

    _handleClickRetry = async e => {
        e.preventDefault()

        this.setState({
            loadingRetry: true
        })

        try {
            await this.props.onRetry(this.props.item)

            this.setState({
                loadingRetry: false
            })
        } catch (e) {
            this.setState({
                loadingRetry: false
            })
        }

    }

    render() {
        const {loadingRetry} = this.state
        const {item} = this.props
        const {status} = item

        return (
            <td>
                <div className='ProductActions'>
                    <div className='Actions'>
                        {
                            status === 'completed' &&
                            <button onClick={this._handleRemove} className='Remove btn btn-danger btn-sm'>
                                <span className='Icon'>Delete</span>
                            </button>
                        }

                        {
                            status === 'not-importable' &&
                            <button disabled={loadingRetry} onClick={this._handleClickRetry}
                                    className='Retry btn btn-warning btn-sm'>
                                <span className='Icon'>Retry</span>
                            </button>
                        }
                    </div>
                </div>
            </td>
        )
    }
}

ProductActions.propTypes = {
    onRetry: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
}

export default ProductActions
