import React, {Component} from "react"
import SettingSectionDetails from "../../setting/shared/SettingSectionDetails"
import {getSettingsByCategory, saveSettingsByCategory} from "../../../services/api/SettingServices"
import GoogleTracking from "./GoogleTracking"
import FacebookTracking from "./FacebookTracking"
import classNames from "classnames";


class SettingTrackingContainer extends Component {
    state = {
        loading: {
            fetchSettings: false,
            spin: false,
        },
        form: {
            "gtm": '',
            'fbpxl': '',
            'google_analytics': '',
            'google_ads': '',
        }
    }

    componentDidMount() {
        this._getSettings()
    }

    _getSettings = async () => {
        this.setState(state => ({
            ...state,
            loading: {
                ...state.loading,
                fetchSettings: true,
            }
        }))

        const {success, data} = await getSettingsByCategory('tracking')
        if (success) {
            const form = data.reduce((accumulator, setting) => ({
                ...accumulator,
                [setting.key]: setting.value,
            }), {})
            this.setState(state => ({
                ...state,
                loading: {
                    ...state.loading,
                    fetchSettings: false,
                },
                form: {
                    ...state.form,
                    ...form,
                }
            }))
        }
    }

    _clickSaveButton = () => {
        this._saveSettings()
    }

    _saveSettings = async () => {
        const {form} = this.state
        this.setState(state => ({
            loading: {
                ...state.loading,
                spin: true,
            }
        }))

        const {success} = await saveSettingsByCategory('tracking', Object.keys(form).map((key) => ({
            key,
            value: form[key]
        })))

        return this.setState(state => ({
            loading: {
                ...state.loading,
                spin: false,
                saveSuccess: success,
            }
        }))
    }

    _onChangeSetting = (key, value) => {
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [key]: value,
            }
        }))
    }

    render() {
        const {loading, form} = this.state
        const spin = loading.spin

        return <div className="SettingContainer">
            <div className="Section">
                <SettingSectionDetails
                    title="Google Tracking"
                />
                <div className="SectionSetting">
                    <div className="card Card">
                        <div className="card-body shadow-sm">
                            <GoogleTracking form={form} loading={loading} onChange={this._onChangeSetting}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Section">
                <SettingSectionDetails
                    title="Facebook Tracking"
                />
                <div className="SectionSetting">
                    <div className="card Card">
                        <div className="card-body shadow-sm">
                            <FacebookTracking form={form} loading={loading} onChange={this._onChangeSetting}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ButtonSave">
                <button
                    className="btn btn-primary"
                    onClick={this._clickSaveButton}
                    disabled={loading.spin}
                >
                    <span className="Label">Save</span>
                    <span className={classNames("Spin", {spin})}/>
                </button>
            </div>
        </div>
    }
}

export default SettingTrackingContainer
