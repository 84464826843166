export const productStatus = {
    'is_deleted': 'Deleted',
    'is_active': 'Active',
    'is_stealthy': 'Stealthy',
    'is_marketplace_visible': 'Marketplace visible',
    'is_taken_down': 'Taken down',
    'is_sold_hidden': 'Sold hidden',
    'other': 'Unknown'
}

export const productStatusOrders = (productName) => ({
    'Active': `Make ${productName} available`,
    'Deactive': `Make ${productName} unavailable`
})
