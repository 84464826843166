import React, {Component} from "react"
import {Switch, Route, Redirect, Link} from "react-router-dom"
import {settings} from '../../../statics/settings'
import SettingGeneralContainer from "../../setting-general/components/SettingGeneralContainer"
import SettingPaymentContainer from "../../setting-payment/components/SettingPaymentContainer"
import SettingProductContainer from "../../setting-product/components/SettingProductContainer"
import SettingTrackingContainer from "../../setting-tracking/components/SettingTrackingContainer"

class SettingContainer extends Component {
    _getMenuTitle = () => {
        const {pathname} = this.props.location
        const currentMenu = settings.find(setting => setting.location === pathname)

        if (currentMenu) return currentMenu.title

        return 'Setting'
    }

    render() {
        return (
            <div className="SettingContainer">
                <div className="container-fluid">
                    <div className="BackWrapper">
                        <Link className="nav-link BackButton" to="/a/settings">
                            <i className="fas fa-chevron-left"/>
                            <span>Settings</span>
                        </Link>
                    </div>
                    <div className="MenuTitle">
                        {this._getMenuTitle()}
                    </div>
                    <Switch>
                        <Route exact path="/a/settings/general" component={SettingGeneralContainer}/>
                        <Route exact path="/a/settings/payment" component={SettingPaymentContainer}/>
                        <Route exact path="/a/settings/product" component={SettingProductContainer}/>
                        <Route exact path="/a/settings/tracking" component={SettingTrackingContainer}/>
                        <Redirect to="/a/settings"/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default SettingContainer

