import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({suffix: '/importer'})

export const fetchListJobs = (data = {}) => {
    return api.makeAuthRequest({
        url: '/jobs/search',
        method: 'POST',
        data: data
    })
}

export const newImporterJob = (data = {}) => {
    return api.makeAuthRequest({
        url: `/jobs`,
        method: 'POST',
        data,
    })
}

export const removeProductViaJob = (jobId) => {
    return api.makeAuthRequest({
        url: `/jobs/${jobId}/remove-product`,
        method: 'DELETE',
    })
}

export const retryJob = (jobId) => {
    return api.makeAuthRequest({
        url: `/jobs/${jobId}/retry`,
        method: 'POST',
    })
}

export const searchCollections = (data) => {
    return api.makeAuthRequest({
        url: '/collections/search',
        method: 'POST',
        data,
    })
}

export const bulkImport = (data) => {
    return api.makeAuthRequest({
        headers: {
            'content-type': 'multipart/form-data'
        },
        url: '/jobs/bulk',
        method: 'POST',
        data,
    })
}

