import React, {Component} from 'react'
import getHistory from "../../../store/getHistory"
import * as AuthServices from "../../../services/AuthServices"
import parseSearchQuery from "../../../helpers/routing/parseSearchQuery"
import {getUserData} from "../../../services/api/AccountServices"

class OauthCallback extends Component {
    componentDidMount() {
        this._initSetup()
    }

    _initSetup = () => {
        const {accessToken, refreshToken, expiresIn} = this._getQueries()
        const history = getHistory()

        if (!accessToken || !refreshToken) {
            return history.push('/')
        }

        setTimeout(async () => {
            this._setup({accessToken, refreshToken, expiresIn})
        }, 0)
    }

    _setup = async ({accessToken, refreshToken, expiresIn}) => {
        const history = getHistory()
        AuthServices.setAccessToken(accessToken)
        AuthServices.setRefreshToken(refreshToken)

        try {
            const {success, data, message} = await getUserData()
            if (!success) {
                throw new Error(message)
            }

            AuthServices.setUserData(data)
        } catch (e) {
            window.alert(e.message || e)
        }

        return history.push('/')
    }

    _getQueries = () => {
        return parseSearchQuery()
    }

    render() {
        return (
            <div className="OauthCallback">
                <div className="text-center">Loading...</div>
            </div>
        )
    }
}

export default OauthCallback
