import React, {Component} from "react"
import SettingInput from "../../setting/shared/SettingInput"
import SettingCheckbox from "../../setting/shared/SettingCheckbox"
import SettingSelect from "../../setting/shared/SettingSelect"
import PropTypes from "prop-types"

class Paypal extends Component {
    _changeSettings = (key) => value => {
        if (key === 'paypal_enabled') {
            return this.props.onChange(key, value ? 'enabled' : 'disabled')
        }
        return this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="Paypal">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Paypal client key"
                            itemId="paypal_client_key"
                            disabled={loading.fetchSettings}
                            value={form['paypal_client_key'] || ''}
                            onChange={this._changeSettings('paypal_client_key')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Paypal secret key"
                            itemId="paypal_secret_key"
                            disabled={loading.fetchSettings}
                            type="password"
                            value={form['paypal_secret_key'] || ''}
                            onChange={this._changeSettings('paypal_secret_key')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingSelect
                            label="Paypal env"
                            itemId="paypal_env"
                            disabled={loading.fetchSettings}
                            value={form['paypal_env'] || ''}
                            options={[{
                                title: "Production",
                                value: "production"
                            }, {
                                title: "Sandbox",
                                value: "sandbox",
                            }]}
                            onChange={this._changeSettings('paypal_env')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            label="Enable Paypal"
                            itemId="paypal_enabled"
                            disabled={loading.fetchSettings}
                            checked={form['paypal_enabled'] === 'enabled'}
                            onChange={this._changeSettings('paypal_enabled')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

Paypal.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Paypal
