import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dashboard from "./Dashboard"
import EnsureLoggedIn from "../../shared/EnsureLoggedIn"
import WithStoreSettings from "../../shared/WithStoreSettings"
import {Helmet} from "react-helmet"

class DashboardContainer extends Component {
    componentDidMount() {
        this.props.store.refresh()
    }

    _renderHead = () => {
        const {store} = this.props
        const storeName = store.getSetting('name', '')

        return (
            <Helmet>
                {
                    !!storeName &&
                    <title>{storeName}</title>
                }
            </Helmet>
        )
    }

    render() {
        return (
            <EnsureLoggedIn>
                {this._renderHead()}
                <Dashboard {...this.props} basePath="/a"/>
            </EnsureLoggedIn>
        )
    }
}

DashboardContainer.propTypes = {
    store: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {(store) => {
                return (
                    <DashboardContainer {...props} store={store}/>
                )
            }}
        </WithStoreSettings>
    )
}
