import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StatisticsFilter from "./StatisticsFilter"
import OrderStatisticsOverview from "./OrderStatisticsOverview"
import EventStatistics from "./EventStatistics"
import PubSub from "../../PubSub"

class OrderStatisticsContainer extends Component {
    state = {
        query: {
            created: {
                from: '1-day-ago'
            }
        }
    }

    _handleChangeQuery = (updater) => {
        const {query} = this.state

        if (typeof updater === 'function') {
            const updatedQuery = updater(query)

            return this.setState({
                query: {
                    ...query,
                    ...updatedQuery
                }
            }, this._handleAfterUpdatingQuery)
        }

        this.setState({
            query: {
                ...query,
                ...updater
            }
        }, this._handleAfterUpdatingQuery)
    }

    _handleAfterUpdatingQuery = () => {
        PubSub.publish('STATISTICS_FILTER_UPDATED', [this.state.query])
    }

    render() {
        const {query} = this.state

        return (
            <div className="OrderStatisticsContainer">
                <StatisticsFilter query={query} onChangeQuery={this._handleChangeQuery}/>
                <div className="Statistics row">
                    <OrderStatisticsOverview query={query}/>
                    <EventStatistics query={query}/>
                </div>
            </div>
        )
    }
}

OrderStatisticsContainer.propTypes = {
    store: PropTypes.object.isRequired,
}

export default OrderStatisticsContainer
