import React, {Component} from "react"
import PropTypes from "prop-types"

import Input from "../shared/Input"

class GeneralEdit extends Component {

    _changeInput = (property) => (value) => {
        this.props.onChange(property, value)
    }

    render() {
        const {product} = this.props

        return <div className="GeneralEdit">
            <div className="SectionInner">
                <Input
                    label="Title"
                    value={product.title}
                    onChange={this._changeInput('title')}
                />
                <Input
                    label="Description"
                    value={product.description}
                    onChange={this._changeInput('description')}
                    textarea={true}
                />
            </div>
        </div>
    }
}

GeneralEdit.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default GeneralEdit
