import React, {Component} from 'react'
import Modal from 'react-awesome-modal'
import PropTypes from 'prop-types'
import {editTracking} from "../../../services/api/OrdersServices"

const listShippingCarrier = [
    'None', '4PX', 'APC', 'Amazon Logistics UK', 'Amazon Logistics US', 'Australia Post', 'Bluedart', 'Canada Post',
    'China Post', 'Correios', 'DHL Express', 'DHL eCommerce', 'DHL eCommerce Asia', 'DPD', 'DPD Local', 'DPD UK',
    'Delhivery', 'Eagle', 'FSC', 'FedEx', 'FedEx UK', 'GLS', 'Globegistics', 'Japan Post (EN)', 'Japan Post (JA)',
    'La Poste', 'New Zealand Post', 'Newgistics', 'PostNL', 'PostNord', 'Purolator', 'Royal Mail', 'SF Express',
    'Sagawa (EN)', 'Sagawa (JA)', 'Singapore Post', 'TNT', 'UPS', 'USPS', 'Whistl', 'Yamato (EN)', 'Yamato (JA)', 'Other'
]

class EditTrackingInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            orderId: this.props.orderId,
            fulfilledId: this.props.fulfilledId,
            tracking_number: this.props.tracking_number,
            tracking_url: this.props.tracking_url,
            tracking_company: this.props.tracking_company || 'None',
            notify_customer: this.props.notify_customer
        }
    }

    _handleTrackingNumber = (e) => {
        this.setState({
            tracking_number: e.target.value
        })
        if (e.target.value && this.state.tracking_company === 'None') {
            this.setState({
                tracking_company: 'Other'
            })
        }
    }

    _handleTrackingUrl = (e) => {
        this.setState({
            tracking_url: e.target.value
        })
    }

    _handleTrackingCompany = (e) => {
        if (e.target.value === 'None') {
            return this.setState({
                tracking_company: e.target.value,
                tracking_number: '',
                tracking_url: ''
            })
        }
        this.setState({
            tracking_company: e.target.value
        })
    }

    _handleNotifyCustomer = () => {
        this.setState((state) => {
            return {notify_customer: !state.notify_customer}
        })
    }

    _openModal = () => {
        this.setState({
            isOpen: true
        })
    }

    _closeModal = () => {
        this.setState({
            isOpen: false
        })
    }

    _editTracking = async () => {
        console.log(this.state)
        const {tracking_number, tracking_url, tracking_company, notify_customer, orderId, fulfilledId} = this.state
        const args = {
            tracking_number: tracking_number,
            tracking_company: tracking_company === 'None' ? null : tracking_company,
            tracking_url: tracking_url,
            notify_customer: notify_customer
        }

        const {success, data, message} = await editTracking(orderId, fulfilledId, args)

        if (!success) {
            return alert(message)
        }
        this.props.refreshTracking(data)
        this._closeModal()
    }

    render() {
        const {isOpen, tracking_number, tracking_url, tracking_company, notify_customer} = this.state

        return (
            <Modal visible={isOpen} effect="fadeInUp"
                   onClickAway={() => this._closeModal()}>
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit tracking</h5>
                        <button type="button" className="close" onClick={() => this._closeModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Tracking number</label>
                                    <input type="text" className="form-control"
                                           value={tracking_number}
                                           onChange={(e) => this._handleTrackingNumber(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Shipping carrier</label>
                                    <select id="inputState" className="form-control"
                                            value={tracking_company}
                                            onChange={(e) => this._handleTrackingCompany(e)}
                                    >
                                        {listShippingCarrier.map((ship, i) => {
                                            return <option key={i}>{ship}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            tracking_company === 'Other' ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Tracking url</label>
                                            <input type="text" className="form-control"
                                                   value={tracking_url}
                                                   onChange={(e) => this._handleTrackingUrl(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className="col-12">
                            <div className="form-group">
                                <input type="checkbox" className="form-check-input"
                                       checked={notify_customer}
                                       onChange={() => this._handleNotifyCustomer()}
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">Send shipment details to your customer
                                    now</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-outline-dark"
                                onClick={() => this._closeModal()}>Cancel
                        </button>
                        <button type="button" className="btn btn-sm btn-primary"
                                onClick={() => this._editTracking()}>Save
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

EditTrackingInfo.propTypes = {
    orderId: PropTypes.string.isRequired,
    fulfilledId: PropTypes.string.isRequired,
    tracking_company: PropTypes.string.isRequired,
    tracking_number: PropTypes.string.isRequired,
    tracking_url: PropTypes.string.isRequired,
    notify_customer: PropTypes.bool.isRequired,
    refreshTracking: PropTypes.func.isRequired
}

export default EditTrackingInfo
