import React, {Component} from 'react'

class TableHeadImporter extends Component {
    render() {
        return (
            <thead className='TableHeadImporter thead-light'>
            <tr>
                <th scope='col'>
                    <span>#</span>
                </th>
                <th scope='col'>
                    <span>Sources</span>
                </th>
                <th scope='col'>
                    <span>Destination</span>
                </th>
                <th scope='col'>
                    <span>Collections</span>
                </th>
                <th scope='col'>
                    <span>Tags</span>
                </th>
                <th scope='col'>
                    <span>Updated</span>
                </th>
                <th scope='col'>
                    <span>Status</span>
                </th>
                <th scope='col'>
                    <span>Actions</span>
                </th>
            </tr>
            </thead>
        )
    }
}

export default TableHeadImporter
