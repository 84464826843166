import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getOrderInvoice} from "../../../services/api/OrdersServices"

class OrderPaymentPending extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: props.orderId,
            invoice: {}
        }
    }

    componentDidMount() {
        this._getInvoice()
    }

    _getInvoice = async () => {
        const {success, data, message} = await getOrderInvoice(this.state.orderId)

        if (!success) {
            return this.setState({
                loadingInvoice: false,
                messageInvoice: message
            })
        }

        this.setState({
            loadingInvoice: false,
            invoice: {
                shipping: parseFloat(data.shipping),
                subtotal: parseFloat(data.subtotal),
                total: (parseFloat(data.shipping) + parseFloat(data.subtotal)).toFixed(2)
            }
        })
    }

    render() {
        const {invoice} = this.state
        const total = (invoice.shipping + invoice.subtotal).toFixed(2)

        return (
            <div className="PaymentPending card">
                <div className="card-header font-weight-bold">
                    Payment pending
                </div>
                <div className="card-body text-muted">
                    <div className="row">
                        <p className="col-2 card-text">
                            Shipping
                        </p>
                        <p className="col-10 card-text">
                            : ${invoice.shipping}
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-2 card-text">
                            Subtotal
                        </p>
                        <p className="col-10 card-text">
                            : ${invoice.subtotal}
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-2 card-text">
                            Total
                        </p>
                        <p className="col-10 card-text">
                            : ${total}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

OrderPaymentPending.propTypes = {
    orderId: PropTypes.string.isRequired
}

export default OrderPaymentPending
