import React, {Component} from 'react'
import ImporterPage from './ImporterPage'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import {Link} from "react-router-dom";

class ImporterPageContainer extends Component {
    render() {
        return (
            <section className="ImporterPageContainer ImporterContainers">
                <DocTitleByStore title="Importer"/>

                <h1 className="PageTitle">Importer</h1>

                <div className="SectionInner">
                    <Link to={'/a/importer/new'} className="ButtonImportProduct">
                        <button
                            className='btn btn-primary'>
                            <i className="ti-download"/>
                            Import Product
                        </button>
                    </Link>
                    <ImporterPage/>
                </div>
            </section>
        )
    }
}

export default ImporterPageContainer
