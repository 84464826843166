import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/google-shopping' : 'https://pro.merch8.com/api/google-shopping'
const api = createAPIServices({baseUrl})

export const googleShoppingOauth = (storeId, env = 'prod') => {
    return api.makeAuthRequest({
        url: '/oauth',
        method: 'POST',
        data: {
            storeId,
            env,
        }
    })
}

