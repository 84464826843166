import {createStoreAPI} from "./createStoreAPIService"

const api = createStoreAPI({
    getBaseURL: (currentStore, isProduction = false, getStoreSetting) => {
        const domain = getStoreSetting('domain', 'merch8.com')

        return `https://${domain}/api/importer`
    }
})

const _getHeaders = () => {
    return {
        'Authorization': 'f562c3e9ee9e38284cea49228d50b0fbd21ab3954293c00aed0ed634471e04fc',
    }
}

export const importProduct = (data = {}) => {
    return api.makeAuthRequest({
        headers: _getHeaders(),
        url: '/enqueue',
        method: 'POST',
        data: {
            ...data
        }
    })
}

export const removeItem = (itemId) => {
    return api.makeAuthRequest({
        headers: _getHeaders(),
        url: `/product/delete/${itemId}`,
        method: 'DELETE',
    })
}

export const getListItems = ({params = ''}) => {
    return api.makeAuthRequest({
        headers: _getHeaders(),
        url: '/queue/list',
        method: 'GET',
        params: params
    })
}

export const getCollections = ({params = ''}) => {
    return api.makeAuthRequest({
        headers: _getHeaders(),
        url: '/collections',
        method: 'GET',
        params: params
    })
}

export const saveCollection = (collectionName) => {
    return api.makeAuthRequest({
        headers: _getHeaders(),
        url: '/collections',
        method: 'POST',
        data: {
            name: collectionName,
        }
    })
}