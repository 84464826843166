import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OrderTagsColumn extends Component {
    _getListTags = () => {
        const {order: {tags}} = this.props
        const validatedTags = Array.isArray(tags) ? tags : []

        return validatedTags.filter(tag => tag && tag._id)
    }

    render() {
        const tags = this._getListTags()

        return (
            <td className="OrderTagsColumn">
                <div className="ListTags">
                    {tags.map(tag => {
                        const {_id, title} = Object.assign({}, tag)

                        return (
                            <span key={_id} className="TagItem badge badge-info">{title}</span>
                        )
                    })}
                </div>
            </td>
        )
    }
}

OrderTagsColumn.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderTagsColumn
