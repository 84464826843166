import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {fetchEventStatistics} from "../../../../services/api/NewStatisticsServices"
import StatisticBox from "./StatisticBox"
import PubSub from '../../PubSub'

class EventStatistics extends Component {
    state = {
        statistics: {},
        loading: false,
        error: ''
    }

    _refresh = null
    _changeQuery = null

    componentDidMount() {
        this._setupChangeQuery()
        this._setUpRefresh()
        this._fetchStatistics()
    }

    componentWillUnmount() {
        this._destroyChangeQuery()
        this._destroyRefresh()
    }

    _setupChangeQuery = () => {
        this._changeQuery = PubSub.subscribe('STATISTICS_FILTER_UPDATED', () => {
            this._fetchStatistics()
        })
    }

    _destroyChangeQuery = () => {
        this._changeQuery && PubSub.unsubscribe(this._changeQuery)
    }

    _setUpRefresh = () => {
        if (this._refresh) clearInterval(this._refresh)

        this._refresh = setInterval(() => {
            this._fetchStatistics()
        }, 10000)
    }

    _destroyRefresh = () => {
        this._refresh && clearInterval(this._refresh)
    }

    _fetchStatistics = async () => {
        if (this.state.loading) return false

        this.setState({
            loading: true,
            error: ''
        })

        const {query: {created}} = this.props

        try {
            const {success, data, message} = await fetchEventStatistics({created})

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            this.setState({
                statistics: Object.assign({}, data),
                loading: false,
                error: ''
            })
        } catch (e) {
            this.setState({
                error: e.message || e,
                loading: false
            })
        }
    }

    _getUniqueValue = (args = {}) => {
        const {unique} = Object.assign({}, args)

        return unique > 0 ? parseInt(unique, 10) : 0
    }

    _getTotalValue = (args = {}) => {
        const {total} = Object.assign({}, args)

        return total > 0 ? parseInt(total, 10) : 0
    }

    _getDataBox = () => {
        const {statistics} = this.state
        const {ViewProduct, AddToCart, InitiateCheckout, Purchase} = Object.assign({}, statistics)
        const uniqueViewProduct = this._getUniqueValue(ViewProduct)
        const totalAddToCart = this._getTotalValue(AddToCart)
        const totalInitiateCheckout = this._getTotalValue(InitiateCheckout)
        const totalPurchase = this._getTotalValue(Purchase)

        const _getPercent = (a) => {
            return uniqueViewProduct <= 0 ? 0 : parseFloat(a / uniqueViewProduct * 100).toFixed(2)
        }

        return [
            {
                key: 'ViewProduct',
                title: 'ViewProduct',
                value: uniqueViewProduct,
            },
            {
                key: 'AddToCart',
                title: 'AddToCart',
                value: `${totalAddToCart} / ${_getPercent(totalAddToCart)}%`
            },
            {
                key: 'InitiateCheckout',
                title: 'InitiateCheckout',
                value: `${totalInitiateCheckout} / ${_getPercent(totalInitiateCheckout)}%`
            },
            {
                key: 'Purchase',
                title: 'Purchase',
                value: `${totalPurchase} / ${_getPercent(totalPurchase)}%`,
            },
        ]
    }

    render() {
        const data = this._getDataBox()

        return (
            <div className="EventStatistics col-md-4 col-12">
                <div className="StatisticsRow card-deck row">
                    <StatisticBox className="col-12" statistics={data}/>
                </div>
            </div>
        )
    }
}

EventStatistics.propTypes = {
    query: PropTypes.object.isRequired,
}

export default EventStatistics
