import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from "./columns/IndexColumn"
import SourceColumn from "./columns/SourceColumn"
import CollectionsColumn from "./columns/CollectionsColumn"
import DestinationColumn from "./columns/DestinationColumn"
import StatusColumn from "./columns/StatusColumn"
import TagsColumn from "./columns/TagsColumn"
import DateColumn from "./columns/DateColumn"
import ActionsColumn from "./columns/ActionsColumn"

class RenderTableRow extends Component {
    render() {
        const {item, order, onRemove, onRetry} = this.props

        return (
            <tr className="RenderTableRow">
                <IndexColumn order={order}/>
                <SourceColumn item={item}/>
                <DestinationColumn item={item}/>
                <CollectionsColumn item={item}/>
                <TagsColumn item={item}/>
                <DateColumn item={item}/>
                <StatusColumn item={item}/>
                <ActionsColumn onRemove={onRemove} onRetry={onRetry} item={item}/>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    order: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default RenderTableRow
