import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {isArray} from 'util'

class TagsColumn extends Component {
    render() {
        const {item} = this.props
        const tags = isArray(item.tags) ? item.tags : []

        return (
            <td className="TagsColumn">
                <ul className='list-group'>
                    {tags.map(tag => {
                        return (
                            <li key={tag} className='badge badge-info'>
                                {tag}
                            </li>
                        )
                    })}
                </ul>
            </td>
        )
    }
}

TagsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default TagsColumn
