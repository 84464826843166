import React, {Component} from "react"
import ProductsPage from "./ProductsPage"
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

class ProductsPageContainer extends Component {
    render() {
        return (
            <section className="ProductsContainer">
                <h1 className="PageTitle">Products</h1>

                <div className="SectionInner">
                    <DocTitleByStore title="Products"/>
                    <ProductsPage location={this.props.location}/>
                </div>
            </section>
        )
    }
}

export default ProductsPageContainer
