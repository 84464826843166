import React, {Component} from 'react'
import LogoutPage from "./LogoutPage"

class LogoutPageContainer extends Component {
    render() {
        return (
            <div className="LogoutPageContainer">
                <LogoutPage/>
            </div>
        )
    }
}

export default LogoutPageContainer
