import React, {PureComponent} from "react"
import DocumentTitle from "react-document-title"
import PropTypes from "prop-types"

class DocTitle extends PureComponent {
    render() {
        const {title, prefix, suffix, children} = this.props

        return (
            <DocumentTitle title={prefix + title + suffix}>
                {children}
            </DocumentTitle>
        )
    }
}

DocTitle.defaultProps = {
    children: null,
    prefix: '',
    suffix: ''
}

DocTitle.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
}

export default DocTitle
