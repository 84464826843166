import React, {Component} from "react"
import SettingInput from "../../setting/shared/SettingInput"
import SettingCheckbox from "../../setting/shared/SettingCheckbox"
import PropTypes from "prop-types"

class Stripe extends Component {
    _changeSettings = (key) => value => {
        if (key === 'stripe_enabled') {
            return this.props.onChange(key, value ? 'enabled' : 'disabled')
        }
        this.props.onChange(key, value)
    }

    render() {
        const {form, loading} = this.props

        return (
            <div className="Stripe">
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Stripe public key"
                            itemId="stripe_public_key"
                            disabled={loading.fetchSettings}
                            value={form['stripe_public_key'] || ''}
                            onChange={this._changeSettings('stripe_public_key')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingInput
                            label="Stripe secret key"
                            itemId="stripe_secret_key"
                            disabled={loading.fetchSettings}
                            type="password"
                            value={form['stripe_secret_key'] || ''}
                            onChange={this._changeSettings('stripe_secret_key')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SettingCheckbox
                            label="Enable Stripe"
                            itemId="stripe_enabled"
                            disabled={loading.fetchSettings}
                            checked={(form['stripe_enabled'] === 'enabled')}
                            onChange={this._changeSettings('stripe_enabled')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

Stripe.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Stripe
