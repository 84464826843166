import React, {Component} from 'react'
import OrdersTable from './OrdersTable'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"

class OrdersPageContainer extends Component {
    render() {
        return (
            <section className="OrderContainers">
                <h1 className="PageTitle">Orders</h1>
                <div className="SectionInner">
                    <DocTitleByStore title="Orders"/>
                    <OrdersTable/>
                </div>
            </section>
        )
    }
}

export default OrdersPageContainer
