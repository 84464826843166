const config = {
    domain: {
        $default: process.env.REACT_APP_DOMAIN || 'merch8.com',
    },

    token: {
        $default: process.env.REACT_APP_TOKEN || 'f562c3e9ee9e38284cea49228d50b0fbd21ab3954293c00aed0ed634471e04fc'
    },

    baseAPIUrl: {
        $default: 'https://merch8.com/api/importer',
        production: 'https://merch8.com/api/importer'
    },
}

const _getEnvironment = () => {
    return process.env.REACT_APP_ENV || 'development'
}

export default (key, defaultValue = null) => {
    if (!config.hasOwnProperty(key)) {
        return defaultValue
    }

    const env = _getEnvironment()
    const _dataConfig = config[key]

    return _dataConfig[env] ? _dataConfig[env] : _dataConfig['$default'] || defaultValue
}
