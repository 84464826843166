import React, {Component} from 'react'
import AuthorizeButton from "./AuthorizeButton"
import AccountInfo from "./AccountInfo"

class GoogleShopping extends Component {
    state = {
        isAuthorized: false
    }

    _handleChangeStatus = (isAuthorized = false) => {
        this.setState({
            isAuthorized: !!isAuthorized
        })
    }

    render() {
        const {isAuthorized} = this.state

        return (
            <div className="GoogleShopping">
                <div className="Main mt-3">
                    <AuthorizeButton onChangeStatus={this._handleChangeStatus}/>
                    {
                        !!isAuthorized && <AccountInfo/>
                    }
                </div>
            </div>
        )
    }
}

export default GoogleShopping
