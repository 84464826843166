import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import WithStoreSettings from "../../shared/WithStoreSettings"
import {googleShoppingOauth} from "../../../services/api/GSSAuthorizationServices"
import {isAuthorizedGGS} from "../../../services/api/GSSIntegrationServices"
import RevokeButton from "./RevokeButton"

class AuthorizeButton extends Component {
    state = {
        loading: false,
        error: '',
        isAuthorized: false
    }

    componentDidMount() {
        this._checkAuthorized()
    }

    _checkAuthorized = async () => {
        this.setState({
            loading: true,
            error: '',
        })

        try {
            const {success, data, message} = await isAuthorizedGGS()
            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            const isAuthorized = !!data

            this.setState({
                loading: false,
                error: '',
                isAuthorized,
            })

            this.props.onChangeStatus(isAuthorized)
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    _handleClickAuthorize = e => {
        e.preventDefault()

        this._authorize()
    }

    _authorize = async () => {
        const {store} = this.props
        const {storeId} = store

        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {success, data, message} = await googleShoppingOauth(storeId)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            window.location.href = data
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    _handleOnRevokeSuccess = () => {
        this.setState({
            isAuthorized: false
        })

        this.props.onChangeStatus(false)
    }

    render() {
        const {error, loading, isAuthorized} = this.state

        return (
            <div className={classNames("AuthorizeButton mb-3", {loading, hasError: !!error})}>
                {
                    !!error &&
                    <div className="ErrorText">{error}</div>
                }

                {
                    isAuthorized
                        ?
                        <Fragment>
                            <button disabled className="btn btn-secondary">
                                Authorized
                            </button>
                            <RevokeButton onSuccess={this._handleOnRevokeSuccess}/>
                        </Fragment>
                        :
                        <button disabled={loading} onClick={this._handleClickAuthorize} className="btn btn-primary">
                            Authorize
                        </button>
                }
            </div>
        )
    }
}


AuthorizeButton.propTypes = {
    onChangeStatus: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {store => {
                return (
                    <AuthorizeButton {...props} store={store}/>
                )
            }}
        </WithStoreSettings>
    )
}
