import React, {Component} from 'react'
import PropTypes from "prop-types"
import { Pagination as Page, PaginationItem, PaginationLink } from 'reactstrap'

class Pagination extends Component {
    _onClickPage = (page) => (e) => {
        e.preventDefault()
        if (isNaN(page)) return
        this.props.onSwitchPage(page)()
    }

    _calculatePagination = () => {
        const {amount, current} = this.props
        if (amount < 5) return Array.from({length: amount}, (v, i) => i + 1)
        if (current < 4) return [
            ...Array.from({length: 4}, (v, i) => i + 1),
            '...',
            amount,
        ]
        if (current >= 4 && current <= amount - 3) return [
            1,
            '...',
            ...[current - 1, current, current + 1],
            '...',
            amount,
        ]
        return [
            1,
            '...',
            ...Array.from({length: 4}, (v, i) => amount - 3 + i)
        ]
    }


    render() {
        const {current} = this.props
        const pagination = this._calculatePagination()
        const isFirst = current === 1
        const isLast = current === pagination[pagination.length-1]


        return <div className="Pagination">
            <Page>
                {
                    <PaginationItem disabled={isFirst} className="Previous">
                        <PaginationLink previous href="/" onClick={this._onClickPage(current-1)} />
                    </PaginationItem>
                }
                {
                    pagination.map(
                        (page, index) =>
                        <PaginationItem active={page === current} key={index}>
                            <PaginationLink href='/' onClick={this._onClickPage(page)} >
                                {page}
                            </PaginationLink>
                        </PaginationItem>
                    )
                }
                {
                    <PaginationItem disabled={isLast} className="Next">
                        <PaginationLink next href="/" onClick={this._onClickPage(current+1)} />
                    </PaginationItem>
                }
            </Page>
        </div>
    }
}

Pagination.propTypes = {
    amount: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onSwitchPage: PropTypes.func.isRequired,
}

export default Pagination
