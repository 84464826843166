import React, {Component} from 'react'
import ChangeToStore from "./ChangeToStore"

class ChangeToStoreContainer extends Component {
    render() {
        const {match: {params: {storeId}}} = this.props

        return (
            <ChangeToStore id={storeId}/>
        )
    }
}

export default ChangeToStoreContainer
