import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

class OrderNumberColumn extends Component {
    render() {
        const {nameColumn, orderCode, orderId} = this.props

        return (
            <td key={nameColumn}>
                <Link to={'/a/orders/' + orderId}>
                    {orderCode || null}
                </Link>
            </td>
        )
    }
}

OrderNumberColumn.propTypes = {
    nameColumn: PropTypes.string.isRequired,
    orderCode: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired
}

export default OrderNumberColumn
