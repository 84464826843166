import React, {Component} from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import {productStatusOrders} from "../../../statics/assets/products"

class ProductAction extends Component {
    state = {showMenu: false}

    componentDidMount() {
        window.addEventListener('click', this._handleOnClick, false)
    }

    _toggleMenu = (action) => (e) => {
        if (!action)
            this.setState(state => ({
                ...state,
                showMenu: !state.showMenu,
            }))
        else
            this.setState(state => ({
                ...state,
                showMenu: (action === "open")
            }))
    }

    _selectAction = (value) => (e) => {
        this.props.onSelectOption(value)
    }

    render() {
        const {showMenu} = this.state
        const {selectedItems} = this.props
        const productName = (Object.keys(selectedItems).filter(key => selectedItems[key]).length <= 1) ? `product` : `products`

        return <div className="ProductAction">
            <button className="ActionButton" onClick={this._toggleMenu()} id="ACTION_BUTTON">Action</button>
            <div className={classnames("ListAction", {
                "Show": showMenu
            })}>
                <ul>
                    {Object.keys(productStatusOrders(productName)).map((key, index) => <li
                        key={index}
                        onClick={this._selectAction(key)}
                        id={`SELECT_ACTION_${index}`}
                    >
                        {productStatusOrders(productName)[key]}
                    </li>)}
                </ul>
            </div>
            {(showMenu) ? <div className="Background" onClick={this._toggleMenu('close')}/> : null}
        </div>
    }
}

ProductAction.propsTypes = {
    selectedItems: PropTypes.object.isRequired,
    onSelectOption: PropTypes.func.isRequired,
}

export default ProductAction
