import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {saveOrdersNotes, getOrderNote} from "../../../services/api/OrdersServices"

class OrderNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            err: '',
            submitting: {
                fulfillmentNote: false,
                supportNote: false,
            },
            notes: {
                fulfillmentNote: '',
                supportNote: '',
            }
        }
    }

    componentDidMount() {
        this._getNote()
    }

    _getNote = async () => {
        const {success, data, message} = await getOrderNote(this.state.orderId)

        if (!success) {
            return this.setState({
                err: message
            })
        }

        this.setState({
            notes: {
                fulfillmentNote: data.fulfillment_note?data.fulfillment_note: ''
            }
        })
    }

    _handleChangeNote = (key) => e => {
        this.setState({
            notes: {
                ...this.state.notes,
                [key === 'support_note' ? 'supportNote' : 'fulfillmentNote']: e.target.value
            }
        })
    }

    _handleSaveNote = (field) => async (e) => {
        e.preventDefault()
        this.setState({
            submitting: {
                ...this.state.submitting,
                [field]: 'Saving...',
            }
        })
        try {
            const {orderId, notes} = this.state
            const {fulfillmentNote, supportNote} = notes
            const {success, message} = await saveOrdersNotes({
                orderId: orderId,
                fulfillmentNote,
                supportNote,
            })
            if (!success) {
                this.setState({
                    err: message,
                    submitting: {
                        fulfillNote: false,
                        supportNote: false,
                    },
                }, () => alert(this.state.err))
            } else {
                this.setState({
                    submitting: {
                        ...this.state.submitting,
                        [field]: 'Saved!',
                    },
                })
                setTimeout(() => this.setState({
                    submitting: {
                        ...this.state.submitting,
                        [field]: false
                    }
                }), 1000)
            }
        } catch (e) {
            this.setState({
                err: e.message || e.toString(),
                submitting: {
                    fulfillNote: false,
                    supportNote: false,
                },
            }, () => alert(this.state.err))
        }
    }

    render() {
        const {submitting, notes, err} = this.state

        return (
            <div className="OrderNotes">
                <div className="card">
                    <div className="card-header font-weight-bold">
                        Order Notes
                    </div>
                    {
                        !err ?
                            <div className="card-body row">
                                <form
                                    className="form-group col-12 text-right"
                                    onSubmit={this._handleSaveNote('fulfillmentNote')}
                                >
                            <textarea
                                className="form-control mb-1"
                                placeholder="Note for fulfillment"
                                value={notes.fulfillmentNote || ''}
                                onChange={this._handleChangeNote('fulfillment_note')}
                            />
                                    <button
                                        type="submit"
                                        disabled={submitting.fulfillmentNote}
                                        className={`btn btn-${(submitting.fulfillmentNote === 'Saved!') ? `success` : `primary`} btn-sm`}
                                    >
                                        {(submitting.fulfillmentNote) || 'Save'}
                                    </button>
                                </form>
                            </div> : <div>{err}</div>
                    }
                </div>
            </div>
        )
    }
}

OrderNotes.propsType = {
    orderId: PropTypes.string.isRequired
}

export default OrderNotes
