import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import ObjectInspector from 'react-object-inspector'
import {getOrderHistories} from '../../../services/api/OrdersServices'

class OrderHistories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderId: this.props.orderId,
            histories: [],
            loading: true,
            err: ''
        }
    }

    componentDidMount() {
        this._getHistories()
    }

    _getHistories = async () => {
        const {success, data, message} = await getOrderHistories(this.state.orderId)

        if (!success) {
            return this.setState({
                loading: false,
                err: message
            })
        }

        this.setState({
            loading: false,
            histories: data
        })
    }

    _getTime = (created) => {
        return moment(created).tz("Asia/Ho_Chi_Minh").format('LLLL')
    }

    render() {
        const {loading, histories, err} = this.state

        return (
            !loading ?
                <div className="OrderHistories">
                    <h6 className="font-weight-bold">History</h6>
                    {!err ?
                        histories.map((history, i) => {
                            return (
                                <div className="card" key={history._id || i}>
                                    <div className="card-header text-muted">
                                        {this._getTime(history.created)}
                                    </div>
                                    <div className="card-body text-muted">
                                        <div className="row">
                                            <p className="card-text col-2">
                                                Event
                                            </p>
                                            <p className="card-text col-10">
                                                : {history.event}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <p className="card-text col-2">
                                                Description
                                            </p>
                                            <p className="card-text col-10">
                                                : {history.description}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <p className="card-text col-2">
                                                Value
                                            </p>
                                            <div className="card-text col-10">
                                                <ObjectInspector depth={0} path="root.value" data={history.value}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <div>{err}</div>
                    }
                </div> : 'Loading hisories...'
        )
    }
}

OrderHistories.propsType = {
    orderId: PropTypes.string.isRequired
}

export default OrderHistories
