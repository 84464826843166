export const settings = [{
    icon: 'fa fa-cog',
    title: 'General',
    description: 'View and update your store details',
    location: '/a/settings/general',
}, {
    icon: 'far fa-credit-card',
    title: 'Payment',
    location: '/a/settings/payment',
    description: 'Configure payment options for your store'
}, {
    icon: 'fas fa-tshirt',
    title: 'Online Store',
    location: '/a/settings/product',
    description: "Change settings for pages on your online store"
}, {
    icon: 'fas fa-search',
    title: 'Tracking',
    location: '/a/settings/tracking',
    description: 'Add and manage tracking codes on your store'
}]
