import React, {PureComponent} from 'react'
import {NavLink, withRouter} from "react-router-dom"
import PropTypes from 'prop-types'

const navbar = [
    {
        title: 'Home',
        fa: 'ti-home',
        href: '/a/analytics'
    },
    {
        title: 'Orders',
        fa: 'ti-pencil-alt',
        href: '/a/orders'
    },
    {
        title: 'Importer',
        fa: 'ti-import',
        href: '/a/importer',
        label: 'beta'
    },
    {
        title: 'Products',
        fa: 'ti-archive',
        href: '/a/products',
    },
    {
        title: 'Google Shopping',
        fa: 'ti-shopping-cart',
        href: '/a/google-shopping',
    },
]

class NavbarLeft extends PureComponent {
    render() {
        return (
            <div className="NavbarLeft">
                <div className="NavbarLeftInner d-flex justify-content-between">
                    <nav className="PrimaryMenu">
                        {
                            navbar.map((nav) => {
                                return (
                                    <NavLink to={nav.href} className="nav-link"
                                             activeClassName="active" key={nav.href}>
                                    <span className="IconHolder">
                                        <i className={nav.fa}/>
                                    </span>
                                        <span className="Title">{nav.title}</span>
                                        {
                                            'label' in nav &&
                                                <span className="Label badge badge-primary ml-auto">{nav.label}</span>
                                        }
                                    </NavLink>
                                )
                            })
                        }
                    </nav>
                    <nav className="BottomMenu">
                        <NavLink to="/a/settings" className="nav-link"
                                 activeClassName="active" key="/a/settings">
                                    <span className="IconHolder">
                                        <i className="ti-settings"/>
                                    </span>
                            <span className="Title">Settings</span>
                        </NavLink>

                        <a href="http://docs.merchize.com/docs/merchize-documentation/" target="_blank" className="nav-link" rel="noopener noreferrer">
                            <span className="IconHolder"><i className="ti-help-alt"/></span>
                            <span className="Title">Help</span>
                        </a>
                    </nav>
                </div>
            </div>
        )
    }
}

NavbarLeft.propTypes = {
    history: PropTypes.object.isRequired,
}

export default withRouter(NavbarLeft)
