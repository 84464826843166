import React, {Component} from 'react'
import ProductsTable from "./ProductsTable"
import Pagination from "../../shared/Pagination"
import {getAllOrders} from "../../../services/api/ProductServices"
import parseSearchQuery from "../../../helpers/routing/parseSearchQuery"
import changeQuerySearch from "../../../helpers/routing/changeQuerySearch"

class ProductsPage extends Component {
    state = {
        form: {
            filter: '',
            search: '',
            filterOptions: [],
        },
        products: {
            entity: [],
            total: 0,
            currentPage: 0,
            selectedItems: []
        },
    }

    componentDidMount() {
        const queries = parseSearchQuery()
        if (queries["page"]) {
            this._getOrder(queries["page"])
        } else {
            this._getOrder(1)
        }
    }

    _getOrder = async (query = 1) => {
        const page = parseInt(query)
        const queries = parseSearchQuery()

        const {success, data} = await getAllOrders(page)
        if (success && data.products) {
            const {products, total} = data
            this.setState({
                products: {
                    entity: products,
                    total: Math.ceil(total / 10),
                    currentPage: page,
                }
            })
            if (parseInt(queries["page"]) !== query) {
                changeQuerySearch({
                    page: query
                })
            }
        }
    }

    _changeProducts = (products) => {
        if (Array.isArray(products)) {
            return this.setState(state => ({
                ...state,
                products: {
                    ...state.products,
                    entity: products
                }
            }))
        }
    }

    _switchPage = (page) => () => {
        this._getOrder(page)
    }

    render() {
        const {products} = this.state

        return (
            <div className="ProductsPage">
                <ProductsTable
                    products={products.entity}
                    selectedItems={products.selectedItems}
                    changeProducts={this._changeProducts}
                />

                <div className="TableNav">
                    <Pagination amount={products.total} current={products.currentPage} onSwitchPage={this._switchPage}/>
                </div>
            </div>
        )
    }
}

export default ProductsPage
