import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import getStatusText from "../../helpers/getStatusText"

class StatusColumn extends Component {
    render() {
        const {item} = this.props
        const {status} = item
        const statusText = getStatusText(status)

        return (
            <td className={classNames("StatusColumn", status)}>
                <div>
                    <span className="badge badge-secondary">{statusText}</span>
                </div>
            </td>
        )
    }
}

StatusColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default StatusColumn
