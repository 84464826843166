import React from 'react'
import ReactDOM from 'react-dom'
import './statics/assets/css/libs/themify-icons.css'
import './scss/app.scss'
import App from './app/App'
import Router from "react-router-dom/Router"
import getHistory from "./store/getHistory"

ReactDOM.render(
    (
        <Router history={getHistory()}>
            <App/>
        </Router>
    ),
    document.getElementById('root')
)
